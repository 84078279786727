import constants from './constants'
import root from 'window-or-global'

const { LOCAL_STORAGE_KEY } = constants

const { localStorage } = root

const hasLocalStorage = !!localStorage

// Restore the queue from a previous browser session if local storage is available
let restoreStorageJson = (hasLocalStorage && localStorage.getItem(LOCAL_STORAGE_KEY)) || '[]'
const initialQueue = JSON.parse(restoreStorageJson)

let operationPromise = Promise.resolve(initialQueue)

/**
 * Makes all of the operations synchronous, regardless of where the singleton SynchronousQueue is being used.
 * This ensures that the in-memory and local storage queues are synchronized.
 * Easy to add more storage devices as well regardless if there access is asynchronous or not.
 * If the operation is successful, the queue will be backed up in local storage if it is available.
 * @param operation - The operation to be done on the queue.
 * @returns {Promise<Array<EventMessage>>}
 * @ignore
 */
function synchronize (operation) {
  operationPromise = operationPromise.then((currentQueue) => {
    return new Promise((resolve) => {
      resolve(operation(currentQueue))
    })
  }).then((currentQueue) => {
    if (hasLocalStorage) { localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currentQueue)) }

    return currentQueue
  })

  return operationPromise
}

const synchronousQueue =
  {
  /**
   * Adds an EventMessage to the queue.
   * Returns the queue after the operation is complete.
   * @param element - EventMessage to be added to the queue
   * @returns {Promise<Array<EventMessage>>}
   * @memberOf module:harbinger/eventProcessor/synchronousQueue
   */
    add (element) {
      return synchronize((currentQueue) => {
        currentQueue.push(element)

        return currentQueue
      })
    },
    /**
   * Removes the given EventMessage from the queue
   * Returns the queue after the operation is complete.
   * @param element - EventMessage to be removed from the queue
   * @returns {Promise<Array<EventMessage>>}
   * @memberOf module:harbinger/eventProcessor/synchronousQueue
   */
    remove (element) {
      return synchronize((currentQueue) => {
        currentQueue = currentQueue.filter(item => item !== element)

        return currentQueue
      })
    },
    /**
   * Gets the current state of the queue.
   * @returns {Promise<Array<EventMessage>>}
   * @memberOf module:harbinger/eventProcessor/synchronousQueue
   */
    get () {
      return synchronize(first => first)
    },
    /**
   * Clears the queue.
   * @returns {Promise<Array<EventMessage>>}
   * @memberOf module:harbinger/eventProcessor/synchronousQueue
   */
    clear () {
      return synchronize(() => [])
    },
  }

export default synchronousQueue
