import {checkIsInSet, validate} from '../validate'
import constants from './constants'

/**
 * An Event object represents the required and optional params for the
 * API's event creation endpoint, minus those params stored in the
 * Harbinger class.
 * If the constructor is called with invalid values, it returns an
 * empty object instead of an Event instance.
 * @memberOf module:harbinger/models
 */
class Event {
  constructor (config) {
    const { eventType } = config

    const validationContinuation = validate(eventType, 'eventType', checkIsInSet(Object.values(constants.EVENT_TYPES))).and
    Object.defineProperty(this, 'validate', {
      value: validationContinuation,
      writable: false,
      enumerable: false,
    })

    this.eventType = eventType

    this.timestamp = Date.now()
  }
}

export default Event
