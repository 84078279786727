import axios from 'axios'
import constants from './constants'
import packageJson from '../../../package.json'

const url = `${constants.BASE_URL}events`

/**
 * Posts the event to the Harbinger API.
 * Event and configuration parameters must have snake case property names which match what the API expects.
 * @param event - Event to be posted.
 * @param configuration - General configuration for the application in Harbinger.
 * @param context - The context in which the event occurred.
 * @returns {AxiosPromise<any>}
 * @memberOf module:harbinger/eventProcessor/harbingerService
 */
function postEvent (event, configuration, context) {
  const body = JSON.stringify({
    ...event,
    ...configuration,
    context,
  })
  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-API-Key': configuration.api_key,
      'Accept': `application/json, application/vnd.harbinger.v${packageJson.version}+json`,
    },
    withCredentials: true,
  }

  return axios.post(url, body, config)
}

export default postEvent
