import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { map, capitalize, cloneDeep, isEqual, isEmpty } from 'lodash'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  TextField,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const styles = (theme) => ({
  root: {
    margin: '20px 0 20px 20px',
    border: `1px solid ${theme.palette.primary.veryLightGray}`,
    padding: 20,
    overflowY: 'auto',
    position: 'fixed',
    right: 0,
    width: 320,
    top: 100,
    bottom: 0,
  },
  chipGroupStyle: {
    marginTop: 20,
  },
  chipStyle: {
    margin: 5,
  },
  checkboxLabelStyle: {
    display: 'flex',
    height: '35px',
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  mainHeading: {
    height: '25px',
    width: '197px',
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: '21px',
    fontWeight: 500,
    margin: 0,
  },
  subHeadings: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '127px',
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    margin: '5px 0',
  },
  filterContent: {
    boxShadow: 'none',
    border: 'none',
    width: '100%',
    background: 'transparent',
  },
  filterContentDetails: {
    display: 'block',
    paddingTop: 0,
  },
})

const processFiltersForState = (props) => {
  const { selectedFiltersList } = props
  const selectedFiltersListState = {}
  map(selectedFiltersList, (filter, key) => {
    const filtersObj = {}
    map(filter, (filValue) => {
      filtersObj[filValue] = true
    })
    selectedFiltersListState[key] = filtersObj
  })
  return selectedFiltersListState
}

const processFiltersForAPI = (filters) => {
  const filtersToSend = {}
  map(filters, (filterValue, key) => {
    const keys = []
    map(filterValue, (fil, k) => {
      if (fil) {
        keys.push(k)
      }
    })
    filtersToSend[key] = keys
  })
  return filtersToSend
}

class Filters extends React.Component {
  state = {
    filters: processFiltersForState(this.props),
    searchedFilter: {},
  }

  componentDidUpdate = (prevProps) => {
    if (
      !isEqual(prevProps.selectedFiltersList, this.props.selectedFiltersList)
    ) {
      this.setState({ filters: processFiltersForState(this.props) })
    }
  }

  deleteChipHandler = (filKey, val) => {
    this.setState(
      ({ filters }) => {
        const newFilters = cloneDeep(filters)
        newFilters[filKey][val] = false
        return { filters: newFilters }
      },
      () => {
        const filtersToSend = processFiltersForAPI(this.state.filters)
        this.props.tcinSetSelectedFilters(filtersToSend)
      }
    )
  }

  handleFilters = (filterKey, val) => (event) => {
    const checked = event.target.checked
    this.setState(
      (state) => {
        const newState = cloneDeep(state)
        if (filterKey in state.filters) {
          newState.filters[filterKey][val] = checked
          return newState
        } else {
          newState.filters[filterKey] = { [val]: true }
          return newState
        }
      },
      () => {
        const filtersToSend = processFiltersForAPI(this.state.filters)

        this.props.tcinSetSelectedFilters(filtersToSend)
      }
    )
  }
  searchFilter = (filterKey) => (event) => {
    if (!event.target.value) {
      this.setState({ searchedFilter: [] })
      return
    }
    const searchResult = this.props.filtersList[filterKey].filter((str) =>
      str.toLowerCase().includes(event.target.value.toLowerCase())
    )
    this.setState({ searchedFilter: { [filterKey]: searchResult } })
  }

  render() {
    const {
      classes,
      filtersList,
      filterHeading = 'Filter TCIN Table by',
      top = 150,
    } = this.props
    const { filters, searchedFilter } = this.state

    const filtersRenderList = []
    map(filtersList, (filterValues, filterKey) => {
      const chipLabel = Object.keys(filters[filterKey] || {}).length
      const comp = (
        <ExpansionPanel key={filterKey} className={classes.filterContent}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 className={classes.subHeadings}>
              {capitalize(filterKey.replace(/_/g, ' '))}
              {chipLabel ? <Chip label={chipLabel}></Chip> : null}
            </h4>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.filterContentDetails}>
            {filterValues && filterValues !== null && (
              <TextField
                style={{ minWidth: '100%' }}
                id="filter-search"
                label="Search"
                variant="outlined"
                size="small"
                onChange={this.searchFilter(filterKey)}
              />
            )}
            {filterValues &&
              filterValues !== null &&
              (isEmpty(searchedFilter[filterKey])
                ? filterValues
                : searchedFilter[filterKey]
              ).map((val) => {
                return (
                  <FormControlLabel
                    key={val}
                    className={classes.checkboxLabelStyle}
                    control={
                      <Checkbox
                        checked={Boolean(
                          filters[filterKey] && filters[filterKey][val]
                        )}
                        onChange={this.handleFilters(filterKey, val)}
                        value={val}
                      />
                    }
                    label={val}
                  />
                )
              })}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )

      if (!isEmpty(filterValues)) {
        filtersRenderList.push(comp)
      }
    })

    const selectedFiltersChipList = []
    map(filters, (filArr, filKey) => {
      map(filArr, (val, key) => {
        if (val) {
          selectedFiltersChipList.push(
            <Chip
              key={key}
              label={`${capitalize(filKey.replace(/_/g, ' '))}: ${key}`}
              onDelete={() => this.deleteChipHandler(filKey, key)}
              className={classes.chipStyle}
              color="primary"
            />
          )
        }
      })
    })

    return (
      <div className={classes.root} style={{ top: top }}>
        <h3 className={classes.mainHeading}>{filterHeading}</h3>

        <div className={classes.chipGroupStyle}>{selectedFiltersChipList}</div>
        <FormGroup row>{filtersRenderList}</FormGroup>
      </div>
    )
  }
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  filtersList: PropTypes.object,
}

export default withStyles(styles)(Filters)
