import { useContext } from 'react'
import AuthContext from './context'

/**
 * React hook for getting AuthContext value
 *
 * @example
 *    const { isAuthenticated, login, logout } = useAuth()
 */
const useAuth = () => {
  const auth = useContext(AuthContext)

  return auth
}

export default useAuth
