import Session from '../Session'
import isDevEnv from './isDevEnv'
/*
    ____________________________________________________
   | COOKIES                                            |
   |____________________________________________________|
   | Helper methods to manage resources in cookies      |
   |____________________________________________________|
*/

export const cookieGet = (key: string): string | null => {
  if (!key) return null
  const cookies: readonly (readonly [string, string])[] = document.cookie
    .replace(/\s/g, '')
    .split(';')
    .filter((cookie) => cookie.includes('='))
    .map((cookie) => {
      const [key, val]: string[] = cookie.split('=')
      return [key, val]
    })

  if (!cookies.length) return null

  const map: Map<string, string> = new Map(cookies)
  const value = map.get(key)
  return value ? value : null
}

/**
 * a function that should evaluate if a given Single Sign-On cookie matches a given session
 * @param cookieName - the name of the cookie to evaluate
 * @param session    - the current session
 * @returns whether or not the session matches the SSO cookie or not
 */
export type DoesSSOCookieMatch = (
  cookieName: string,
  session: Session
) => boolean
export const matchesCookie: DoesSSOCookieMatch = (cookieName, session) => {
  try {
    const cookie = cookieGet(cookieName)
    if (!cookie) return false
    const cookieVal = JSON.parse(window.atob(cookie))
    return cookieVal.login_status && cookieVal.user === session.userInfo?.lanId
  } catch (e) {
    if (isDevEnv()) {
      console.error(`Praxis Error: could not parse cookie (${cookieName})`)
    }
    return false
  }
}
