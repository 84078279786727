export default {
  APP_CONFIG: {
    DEFAULT_VALUES: {
      SEGMENT: 'No Segment',
    },
  },
  EVENT_TYPES: {
    CUSTOM: 'CUSTOM',
    SERVICE_ERROR: 'SERVICE_ERROR',
    VIEW: 'VIEW',
  },
}
