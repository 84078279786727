import React from 'react'
import PropTypes from 'prop-types'
import { Fab, CircularProgress, Tooltip } from '@material-ui/core'
import { PlayArrow, CloudUpload, Folder } from '@material-ui/icons'
import styled from 'styled-components'
import { startCase } from 'lodash'

const NormalFab = styled(Fab)`
  background-color: #fff;
`

const TaskActionButton = ({
  buttonType = 'start_task',
  onClick = () => {},
  disableBtn = false,
  buttonName = '',
  color = 'gray',
  progress = 0,
}) => {
  const getButton = (buttonElement) => {
    return (
      <React.Fragment>
        {buttonElement}
        {buttonType === 'loading' ? (
          <CircularProgress />
        ) : (
          <CircularProgress
            style={{ color: color }}
            variant="static"
            value={progress}
          />
        )}
      </React.Fragment>
    )
  }
  return (
    <div>
      <Tooltip title={startCase(buttonName || buttonType)}>
        <div>
          <NormalFab
            style={{ color: color }}
            size="small"
            disabled={disableBtn}
            onClick={onClick}
          >
            {buttonType === 'start_task' &&
              getButton(<PlayArrow style={{ position: 'absolute' }} />)}
            {buttonType === 'upload' &&
              getButton(<CloudUpload style={{ position: 'absolute' }} />)}
            {buttonType === 'success' &&
              getButton(<CloudUpload style={{ position: 'absolute' }} />)}
            {buttonType === 'error' &&
              getButton(<CloudUpload style={{ position: 'absolute' }} />)}
            {buttonType === 'loading' && getButton('')}
            {buttonType === 'uploading' &&
              getButton(<CloudUpload style={{ position: 'absolute' }} />)}
            {buttonType === 'download' && <Folder />}
          </NormalFab>
        </div>
      </Tooltip>
    </div>
  )
}

TaskActionButton.propTypes = {
  buttonType: PropTypes.oneOf([
    'start_task',
    'upload',
    'success',
    'error',
    'loading',
    'download',
    'uploading',
  ]),
  onClick: PropTypes.func,
}

export default TaskActionButton
