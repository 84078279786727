import { AuthenticationProps } from '.'
import isDevEnv from '../utils/isDevEnv'

interface DeprecationWarnings
  extends Pick<AuthenticationProps, 'nonce' | 'extraUserInfoFields'> {}
const deprecationWarnings = ({
  nonce,
  extraUserInfoFields,
}: DeprecationWarnings) => {
  if (isDevEnv()) {
    if (nonce) {
      console.warn(
        'Warning: `nonce` prop is now managed internally within `@praxis/component-auth`. Remove this prop from `AuthProvider` or `Authentication`'
      )
    }

    if (extraUserInfoFields) {
      console.warn(
        'Warning: `extraUserInfoFields` prop is no longer needed to add additional ID token fields to `userInfo`. Remove this prop from `AuthProvider` or `Authentication`'
      )
    }
  }
}

export default deprecationWarnings
