import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import styled from 'styled-components'

const AssignedChip = styled(Chip)`
  background-color: rgb(219, 242, 255);
  color: #333;
`
const WipChip = styled(Chip)`
  background-color: rgb(255, 205, 252);
  color: #333;
`
const InProgressChip = styled(Chip)`
  background-color: rgb(230, 212, 255);
  color: #333;
`
const AwaitingReviewChip = styled(Chip)`
  background-color: rgb(255, 207, 131);
  color: #333;
`

const ReviewProgressChip = styled(Chip)`
  background-color: rgb(227, 227, 227);
  color: #333;
`

const ReworkChip = styled(Chip)`
  background-color: rgb(255, 205, 252);
  color: #333;
`

const UnassignedChip = styled(Chip)`
  background-color: rgb(227, 227, 227);
  color: #333;
`

const OnHoldChip = styled(Chip)`
  background-color: #f9e0e0;
  color: #333;
`
const ApprovedChip = styled(Chip)`
  background-color: #c2ffd1;
  color: #333;
`

const TaskStatusLabel = ({ taskStatus = 'ASSIGNED' }) => {
  return (
    <div>
      {taskStatus === 'ASSIGNED' && <AssignedChip label="Assigned" />}
      {taskStatus === 'WIP' && <WipChip label="WIP" />}
      {taskStatus === 'IN_PROGRESS' && <InProgressChip label="In Progress" />}
      {taskStatus === 'AWAITING_REVIEW' && (
        <AwaitingReviewChip label="Awaiting Review" />
      )}
      {taskStatus === 'REVIEW_IN_PROGRESS' && (
        <ReviewProgressChip label="Review in progress" />
      )}
      {taskStatus === 'REWORK' && <ReworkChip label="Rework" />}
      {taskStatus === 'UNASSIGNED' && <UnassignedChip label="Unassigned" />}
      {taskStatus === 'APPROVED' && <ApprovedChip label="Approved" />}
      {taskStatus === 'ON_HOLD' && <OnHoldChip label="On Hold" />}
    </div>
  )
}

TaskStatusLabel.propTypes = {
  taskStatus: PropTypes.oneOf([
    'ASSIGNED',
    'WIP',
    'IN_PROGRESS',
    'AWAITING_REVIEW',
    'REVIEW_IN_PROGRESS',
    'REWORK',
    'UNASSIGNED',
  ]),
}

export default TaskStatusLabel
