import React, { useEffect, useState } from 'react'
import { Typography, Snackbar, makeStyles } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Alert } from '@mui/material'

const useStyles = makeStyles(() => ({
  container: {
    animation: '$progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
    backgroundImage:
      'repeating-linear-gradient(-45deg, #fff, #fff 25px, rgba(0, 0, 0, 0.12) 25px, rgba(0, 0, 0, 0.12) 50px)',
  },
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  wrapper: {
    border: 'dashed rgba(0, 0, 0, 0.12)',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    minHeight: '250px',
  },
}))

const CustomDropzone = ({
  acceptedFiles = ['application/zip'],
  filesLimit = 0,
  text,
  onAdd = () => {},
}) => {
  const styles = useStyles()
  const [error, setError] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [fileList, setFileList] = useState([])
  const [pendingFileCount, setPendingFileCount] = useState(0)
  const [droppedFolderName, setDroppedFolderName] = useState('')
  const setDragCounter = useState(0)[1]

  const inputId = Math.random()

  const handleFileChange = (event) => {
    const selectedFile = event?.target?.files
    if (selectedFile && selectedFile.length) {
      const files = [{ file: event.target.files[0] }]
      if (validateFile(files)) {
        onAdd(files)
      } else {
        showErrorNotification()
      }
    }
  }

  useEffect(() => {
    if (
      pendingFileCount === fileList.length &&
      fileList.length &&
      pendingFileCount
    ) {
      if (validateFile(fileList)) {
        onAdd(fileList, droppedFolderName)
      } else {
        showErrorNotification()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingFileCount, fileList.length, fileList])

  const processItem = (item, countOnly) => {
    const entry = item
    if (entry) {
      if (entry.isFile) {
        entry.file((file) => {
          if (file.type) {
            if (countOnly) {
              setPendingFileCount((prevCount) => prevCount + 1)
            } else {
              setFileList((prev) => [...prev, { file }])
            }
          }
        })
      } else if (entry.isDirectory) {
        const folderName = entry?.fullPath?.split('/')[1]
        if (!droppedFolderName) {
          setDroppedFolderName(() => folderName)
        }
        processDirectory(entry, countOnly)
      }
    }
  }

  const processDirectory = (directory, countOnly) => {
    const reader = directory.createReader()
    reader.readEntries((entries) => {
      entries.forEach((item) => processItem(item, countOnly))
    })
  }

  const handleDrop = async (event) => {
    event.preventDefault()
    const files = event.dataTransfer.items
    setDragging(false)
    setDragCounter(0)
    setFileList([])
    setPendingFileCount(0)

    const length = files.length
    let webKitEntries = []
    for (let i = 0; i < length; i++) {
      const file = files[i].webkitGetAsEntry()
      webKitEntries.push(file)
      processItem(file)
    }
    await new Promise((res) =>
      setTimeout(() => {
        res()
      }, 100)
    )

    for (let i = 0; i < length; i++) {
      const file = webKitEntries[i]
      processItem(file, true)
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const validateFile = (files) => {
    let isValid = true
    for (let i = 0; i < files.length; i++) {
      const { file } = files[i]
      const nameArr = file.name.split('.')
      const extension = '.' + nameArr[nameArr.length - 1].toLowerCase()
      if (!acceptedFiles.includes(extension)) {
        isValid = false
        setError(`${extension.toUpperCase()} is not a valid file format`)
        break
      }
    }
    if (files.length > filesLimit) {
      isValid = false
      setError(
        (msg) =>
          `${msg ? `${msg},` : ''} maximum file limit of ${filesLimit} exceeded`
      )
    }
    if (!isValid) {
      setDroppedFolderName('')
    }
    return isValid
  }

  const showErrorNotification = () => {
    setOpenSnackbar(true)
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
    setError('')
  }

  const handleClick = (e) => {
    const elem = document.getElementById(inputId)
    e.stopPropagation()
    elem.click()
  }

  const handleDragEnter = () => {
    setDragCounter((counter) => {
      const newCounter = counter + 1
      if (newCounter === 1) {
        setDragging(true)
      }
      return newCounter
    })
  }

  const handleDragLeave = () => {
    setDragCounter((counter) => {
      const newCounter = counter - 1
      if (newCounter <= 0) {
        setDragging(false)
      }
      return newCounter
    })
  }

  return (
    <div className={dragging ? styles.container : ''}>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        className={styles.wrapper}
      >
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          id={inputId}
          accept={acceptedFiles}
        />

        <div onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            {text}
          </Typography>
        </div>
        <div onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
          <CloudUploadIcon
            fontSize="large"
            style={{ height: '51px', width: '51px' }}
          />
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={error}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CustomDropzone
