import constants from './constants'
import { postEvent } from './harbingerService'
import synchronousQueue from './synchronousQueue'

const isObject = (value) => value !== null && typeof value === 'object'

let processingPromise = Promise.resolve()

/**
 * Processes the message by attempting to send it to the Harbinger backend.
 * If it fails, it leaves the message on the queue.
 * If it succeeds, removes the EventMessage from the queue
 * @param eventMessage - EventMessage to be posted to the Harbinger API
 * @returns {Promise<Array<EventMessage>|Void>}
 * @private
 */
function process (eventMessage) {
  return postEvent(eventMessage.event, eventMessage.config, eventMessage.deviceMetrics).then(() => synchronousQueue.remove(eventMessage), () => {
    // If the event is older than the configured TTL (in milliseconds) then quit trying to re-POST it.
    if ((Date.now() - eventMessage.event.timestamp) > constants.EVENTS.TTL) {
      return synchronousQueue.remove(eventMessage)
    }

    return Promise.resolve()
  })
}

export default {
  /**
   * Takes an EventMessage, adds it to the queue, and then processes each by sending them to the Harbinger API simultaneously.
   * Will fail if the message's Event is invalid
   * @param eventMessage - EventMessage to be sent to the Harbinger API
   * @returns {Promise<Array<EventMessage>|Error>}
   * @memberof module:harbinger/eventProcessor
   */
  processEvent (eventMessage) {
    if (!isObject(eventMessage) || (!isObject(eventMessage.event) || Object.keys(eventMessage.event).length < 1)) {
      let nullEventMessage = constants.ERROR_MESSAGES.NULL_EVENT
      // eslint-disable-next-line no-console
      console.warn(nullEventMessage)
      return Promise.reject(new Error(nullEventMessage))
    }
    processingPromise = processingPromise.then(() => synchronousQueue.add(eventMessage))
      .then(currentQueue => {
        const allPromises = currentQueue.map(process)

        return Promise.all(allPromises).then(synchronousQueue.get)
      })

    return processingPromise
  },
}
