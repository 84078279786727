import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import _ from 'lodash'
import { TabWrapper } from './TabStyle'

/* ================================== */
// this.props.tabs Data type.
// [
//     {
//       "statusId": "ALL_TASKS",
//       "label": "All Tasks (3)",
//       "status": "All Tasks",
//       "isSelected": true,
//       "tabIndex": 0
//     },...
// ]
/* ================================== */
// this.props.activeTab Data type.
// Number
/* ================================== */

// CustomTab
const TabHeader = ({ tabs, updateTab, activeTab = 0 }) => {
  return (
    <TabWrapper>
      <Tabs
        textColor={'primary'}
        value={activeTab}
        onChange={(event, value) => {
          updateTab(value)
        }}
      >
        {_.map(tabs, (tabItem, i) => {
          return (
            <Tab
              key={i}
              label={tabItem.label}
              value={i}
              disabled={tabItem.disabled}
            />
          )
        })}
      </Tabs>
    </TabWrapper>
  )
}

TabHeader.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      statusId: PropTypes.string,
      label: PropTypes.string,
      status: PropTypes.string,
      isSelected: PropTypes.bool,
      tabIndex: PropTypes.number,
    })
  ),
  updateTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
}

export default TabHeader
