import root from 'window-or-global'

export default class DeviceMetrics {
  constructor (global) {
    global = global || root
    const screen = global.screen || {}
    const navigator = global.navigator || {}
    const { userAgent } = navigator
    const { availWidth, availHeight, width, height } = screen
    const { innerWidth, innerHeight, devicePixelRatio } = global

    this.userAgent = userAgent
    this.viewportWidth = innerWidth
    this.viewportHeight = innerHeight
    this.deviceAvailWidth = availWidth
    this.deviceAvailHeight = availHeight
    this.deviceWidth = width
    this.deviceHeight = height
    this.devicePixelRatio = devicePixelRatio

    Object.freeze(this)
  }
}
