import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  Tooltip,
  Button,
  Divider,
  Badge,
  IconButton,
} from '@material-ui/core'
import {
  AttachFile,
  InsertDriveFile,
  SaveAlt,
  Cancel,
} from '@material-ui/icons'
import CustomAvatar from '../CustomAvatar/CustomAvatar'
import { formatDate, getDisplayDate } from '../TaskSummary/DateHelper'
import { Mention, MentionsInput } from 'react-mentions'
//import { addCommentsToTask } from '../../store/TaskDetails/ActionCreator'// Replace

const mentionStyle = {
  width: '100%',
  padding: 10,
  control: {
    padding: 10,
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      color: '#555',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
}

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
  },
  container: {
    textAlign: 'right',
  },
  commentsContainer: {
    clear: 'both',
    width: '98%',
    marginTop: '32px',
    position: 'absolute',
  },
  avatar: {
    backgroundColor: theme.palette.primary.lightGray,
    width: '49px',
    height: '49px',
  },
  commentsTitle: {
    fontSize: 16,
    fontWeight: '500',
    color: theme.palette.primary.darkFont,
    textAlign: 'left',
    margin: '20px 0 0 0',
  },
  listitem: {
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0,
  },
  listAvatar: {
    marginTop: 5,
  },
  commentContent: {
    color: theme.palette.primary.darkFont,
    lineHeight: '20px',
    textAlign: 'left',
    fontSize: 14,
    display: 'block',
    width: '70%',
  },
  commentDate: {
    color: '#888888',
    lineHeight: '16px',
    textAlign: 'left',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  noComments: {
    width: '100%',
  },
  commentList: {
    margin: '15px 0 40px 0',
    position: 'absolute',
    height: '500px',
    width: '66%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '10px 0 90px 0',
  },
  commentWrapper: {
    margin: '10px 0 0 0',
    display: 'flex',
    width: '70%',
  },
  fileNameStyle: {
    border: '1px solid #777',
    borderRadius: 5,
    padding: 20,
  },
}))

function TaskComments(props) {
  const {
    taskComment,
    setTaskComment = () => {},
    setNewTaskId = () => {},
    setUserIds = () => {},
    addComment,
    taskId,
    commentsList = [],
    commentsLoading,
    taskTcinComments,
    commentsOnLoad,
    onFileAttach = () => {},
    onDiscardComment = () => {},
    uploadLoader,
    removeAttachment = () => {},
    commentFile = {},
    fetchUsers = () => {},
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const fileInputRef = useRef(null)
  const [attachFileLoader, setAttachFileLoader] = useState(false)
  const [tcinCommentRaw, setTcinCommentRaw] = useState('')

  const handleChange = (event, newValue, newPlainTextValue, mentions = []) => {
    setTaskComment(newPlainTextValue)
    setTcinCommentRaw(newValue)
    setUserIds(mentions.map((o) => o.id))

    setNewTaskId(taskId)
  }

  const handleCommentSave = () => {
    addComment()
    removeComment()
  }

  const removeComment = () => {
    onDiscardComment()
    removeAttachmentHandler()
    setTcinCommentRaw('')
  }

  const removeAttachmentHandler = () => {
    removeAttachment()
    setAttachFileLoader(true)
    setTimeout(() => {
      setAttachFileLoader(false)
    }, 100)
  }

  const showCommentsList = (tcinComments = []) => {
    let message = []
    if (tcinComments.length > commentsList.length && commentsOnLoad) {
      message = tcinComments
    } else {
      message = commentsList
    }
    const messageList = _.sortBy(message, function (o) {
      return formatDate(o.created_date)
    }).reverse()

    return messageList.map((message, key) => {
      return (
        <div key={key}>
          <ListItem className={classes.listitem}>
            <ListItemAvatar className={classes.listAvatar}>
              <CustomAvatar
                isCard
                data={{
                  displayName: message.display_name,
                  loginId: message.created_by,
                }}
                customClasses={classes.avatar}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  component="span"
                  className={classes.commentContent}
                  color="textPrimary"
                >
                  {message.display_name}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    className={classes.inline + ' ' + classes.commentDate}
                    color="textPrimary"
                  >
                    {getDisplayDate(message.created_date)}
                  </Typography>
                  <Typography
                    component="span"
                    className={classes.commentContent}
                    color="textPrimary"
                  >
                    {message.comment_text}
                  </Typography>
                  <br />
                  {message.file_name && (
                    <div>
                      {message.downloadButton}
                      {/* <Button variant='outlined'>
                        <SaveAlt style={{ color: '#9eb9ed' }} />
                        &nbsp;{message.file_name}
                      </Button> */}
                    </div>
                  )}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider />
        </div>
      )
    })
  }

  const onFileChange = () => {
    const file = fileInputRef.current.files[0]
    onFileAttach(file)
  }

  return (
    <div className={classes.commentsContainer}>
      <div className={classes.commentWrapper}>
        <MentionsInput
          value={tcinCommentRaw}
          onChange={handleChange}
          placeholder="Type your comment..."
          style={mentionStyle}
          // disabled={loading}
        >
          <Mention
            displayTransform={(user, dis) => `@${dis}`}
            trigger="@"
            data={fetchUsers}
            appendSpaceOnAdd
          />
        </MentionsInput>
        {/* <TextField
          placeholder="Type your comment..."
          type="text"
          value={taskComment}
          onChange={handleChange}
          fullWidth
          //disabled={loading}
          variant="outlined"
        /> */}
        {!attachFileLoader && (
          <label>
            <Tooltip title="Attach file">
              <AttachFile
                color="primary"
                style={{ fontSize: '2.5em', margin: '7px 0 0 10px' }}
              />
            </Tooltip>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={fileInputRef}
              onChange={onFileChange}
            />
          </label>
        )}
      </div>
      {!_.isEmpty(commentFile.fileName) && (
        <div>
          <br />
          <Badge
            badgeContent={
              uploadLoader ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton onClick={removeAttachmentHandler}>
                  <Cancel />
                </IconButton>
              )
            }
          >
            <Button variant="outlined">
              <InsertDriveFile style={{ color: '#9eb9ed' }} />
              &nbsp;{commentFile.fileName}
            </Button>
          </Badge>
        </div>
      )}
      <br />
      <div>
        <Button
          disabled={uploadLoader || !(taskComment || commentFile.fileName)}
          onClick={handleCommentSave}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
        &nbsp; &nbsp;
        <Button
          disabled={uploadLoader || !(taskComment || commentFile.fileName)}
          color="primary"
          onClick={removeComment}
        >
          Discard Comment
        </Button>
      </div>
      <div className={classes.commentList}>
        {commentsLoading ? (
          <CircularProgress />
        ) : (
          <List className={classes.root}>
            {showCommentsList(taskTcinComments)}
          </List>
        )}
      </div>
    </div>
  )
}
// }

TaskComments.propTypes = {
  classes: PropTypes.object.isRequired,
  lanId: PropTypes.string,
  email: PropTypes.string,
  itemDetails: PropTypes.object,
  projectId: PropTypes.string,
  //addCommentsToTask: PropTypes.func.isRequired,
  messages: PropTypes.array,
  commentsDetails: PropTypes.object,
  tcin: PropTypes.string,
}

export default TaskComments
