import {checkIsNumber, checkStringIsNonEmpty, getCreationFailedMessage} from '../validate'
import constants from './constants'
import CustomDataPoint from './CustomDataPoint'
import Event from './Event'

/**
 * Event for tracking when a service (API) returns an HTTP error.
 * @memberOf module:harbinger/models
 */
class ServiceErrorEvent extends Event {
  /**
   * Constructs the necessary custom data points using the given URL and HTTP status code.
   * If the construction of the object is unsuccessful, it returns an empty object.
   * @param url {string}
   * @param code {number} - An HTTP status code.
   * @returns {ServiceErrorEvent|{}}
   */
  constructor (url, code) {
    super({ eventType: constants.EVENT_TYPES.SERVICE_ERROR })

    this.validate(url, 'url', checkStringIsNonEmpty)
      .and(code, 'code', checkIsNumber)
      .done(getCreationFailedMessage('ServiceErrorEvent'))

    this.customDataPoints = [
      new CustomDataPoint('service_error_request_url', url),
      new CustomDataPoint('service_error_http_code', null, code),
    ]

    Object.freeze(this)
  }
}

export default ServiceErrorEvent
