/* eslint-disable no-unused-vars */
import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  Avatar,
  CircularProgress,
} from '@material-ui/core'
import {
  Clear as ClearIcon,
  Assignment as AssignmentIcon,
} from '@material-ui/icons'
import {
  ActionContainer,
  DrawerContent,
  CloseButton,
  MainContent,
  CycleSection,
  TcinSection,
  IconSection,
  SectionTitle,
  TcinListItems,
  TcinDetailSection,
  TcinBlock,
  TaskBlock,
  SectionTaskTitle,
  SectionCommentTitle,
  LoaderStyle,
  FileListContainer,
} from './TaskSummaryStyle'
import TcinDetailsLabel from './TcinDetailsLabel'
import { getDisplayDate } from './DateHelper'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TaskActionButton from '../TaskActionButton/TaskActionButton'
import TaskComments from '../Comments/TaskComments'

const drawerWidth = 1280

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: '132px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  rounded: {
    color: '#fff',
    backgroundColor: '#7429HH',
    width: '150px',
    height: '150px',
    border: '1px solid #eee',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

const TaskSummary = (props) => {
  const {
    window,
    openDrawer,
    closeDrawer,
    taskComment,
    setTaskComment,
    taskDetails,
    taskSummaryLoader,
    setNewTaskId,
    addComment,
    commentsLoading,
    taskTcinComments,
    commentsOnLoad,
    downloadFileVersions,
    downloadStylingDocs = () => {},
    taskSummaryActionButtons,
    onDiscardComment,
    onFileAttach,
    uploadLoader,
    removeAttachment,
    commentFile,
    fetchUsers = () => {},
    setUserIds = () => {},
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const drawer = () => {
    return (
      <DrawerContent>
        <ActionContainer>
          <CloseButton
            size="small"
            onClick={() => {
              closeDrawer()
            }}
          >
            <ClearIcon />
          </CloseButton>
        </ActionContainer>
        {taskSummaryLoader ? (
          <LoaderStyle>
            <CircularProgress size={60} />
          </LoaderStyle>
        ) : (
          <MainContent>
            <TcinBlock container xs={12}>
              <Grid item xs={12} sm={3}>
                <CycleSection></CycleSection>
                <TcinSection>
                  {(taskDetails && taskDetails.tcin_detail.tcin) || 'NA'}
                </TcinSection>
                {!_.isEmpty(taskDetails && taskDetails.production_file) ? (
                  <IconSection>
                    {taskSummaryActionButtons(
                      taskDetails && taskDetails.production_file
                    )}
                  </IconSection>
                ) : null}
              </Grid>
              <Grid container xs={12} sm={9}>
                <Grid container xs={12} sm={2}>
                  <Avatar
                    variant="rounded"
                    className={classes.rounded}
                    src={taskDetails && taskDetails.tcin_detail.thumbnail_url}
                  />
                </Grid>
                <SectionTitle>TCIN Details</SectionTitle>
                <TcinDetailSection container xs={12} sm={8}>
                  <br />
                  <TcinListItems>
                    <TcinDetailsLabel
                      label="TCIN: "
                      value={
                        <span className={classes.textFormat}>
                          {(taskDetails && taskDetails.tcin_detail.tcin) ||
                            'NA'}
                        </span>
                      }
                      type="string"
                    />
                    <TcinDetailsLabel
                      label="DPCI: "
                      value={
                        <span className={classes.textFormat}>
                          {(taskDetails && taskDetails.tcin_detail.dpci) ||
                            'NA'}
                        </span>
                      }
                      type="string"
                    />
                    <TcinDetailsLabel
                      label="Brand: "
                      value={
                        <span className={classes.textFormat}>
                          {(taskDetails &&
                            taskDetails.tcin_detail.brand_name) ||
                            'NA'}
                        </span>
                      }
                      type="string"
                    />
                    <TcinDetailsLabel
                      label="Department: "
                      value={
                        <span className={classes.textFormat}>
                          {(taskDetails &&
                            taskDetails.tcin_detail.department_name) ||
                            'NA'}
                        </span>
                      }
                      type="string"
                    />
                  </TcinListItems>
                  <TcinListItems>
                    <TcinDetailsLabel
                      label="Description: "
                      value={
                        <span
                          className={classes.textFormat}
                          dangerouslySetInnerHTML={{
                            __html:
                              (taskDetails &&
                                taskDetails.tcin_detail.product_desc) ||
                              'NA',
                          }}
                        />
                      }
                      type="string"
                    />
                    <TcinDetailsLabel
                      label="Relationship Type: "
                      value={
                        <span className={classes.textFormat}>
                          {(taskDetails &&
                            taskDetails.tcin_detail.relationship_type) ||
                            'NA'}
                        </span>
                      }
                      type="string"
                    />
                    <TcinDetailsLabel
                      label="Launch Date: "
                      value={
                        <span className={classes.textFormat}>
                          {taskDetails &&
                          taskDetails.tcin_detail.tcin_launch_date
                            ? getDisplayDate(
                                taskDetails &&
                                  taskDetails.tcin_detail.tcin_launch_date
                              ).substring(0, 10)
                            : 'NA'}
                        </span>
                      }
                      type="string"
                    />
                  </TcinListItems>
                </TcinDetailSection>
              </Grid>
            </TcinBlock>
            <Divider variant="middle" component="div" />
            <TaskBlock container xs={12}>
              <Grid container xs={12} sm={3}>
                <section>
                  <SectionTaskTitle>Task Details</SectionTaskTitle>
                  <TcinDetailsLabel
                    label="Project: "
                    value={
                      <span className={classes.textFormat}>
                        {(taskDetails &&
                          taskDetails.task_detail.project_name) ||
                          'NA'}
                      </span>
                    }
                    type="string"
                  />
                  <TcinDetailsLabel
                    label="Due Date: "
                    value={
                      <span className={classes.textFormat}>
                        {taskDetails && taskDetails.task_detail.due_date
                          ? getDisplayDate(
                              taskDetails && taskDetails.task_detail.due_date
                            ).substring(0, 10)
                          : 'NA'}
                      </span>
                    }
                    type="string"
                  />
                  <TcinDetailsLabel
                    label="Created Date: "
                    value={
                      <span className={classes.textFormat}>
                        {taskDetails && taskDetails.task_detail.created_date
                          ? getDisplayDate(
                              taskDetails &&
                                taskDetails.task_detail.created_date
                            ).substring(0, 10)
                          : 'NA'}
                      </span>
                    }
                    type="string"
                  />
                  <TcinDetailsLabel
                    label="Updated Date: "
                    value={
                      <span className={classes.textFormat}>
                        {taskDetails && taskDetails.task_detail.updated_date
                          ? getDisplayDate(
                              taskDetails &&
                                taskDetails.task_detail.updated_date
                            ).substring(0, 10)
                          : 'NA'}
                      </span>
                    }
                    type="string"
                  />
                </section>
                <section>
                  {downloadStylingDocs(
                    taskDetails && taskDetails.styling_documents
                  )}
                </section>
                {!_.isEmpty(taskDetails && taskDetails.agency_uploads_info) ? (
                  <FileListContainer>
                    <SectionTaskTitle>Uploaded Versions</SectionTaskTitle>
                    <div>
                      {downloadFileVersions(
                        taskDetails && taskDetails.agency_uploads_info
                      )}
                    </div>
                  </FileListContainer>
                ) : null}
              </Grid>
              <Grid container xs={12} sm={9}>
                <SectionCommentTitle>Task Comments</SectionCommentTitle>
                <TaskComments
                  taskComment={taskComment}
                  setUserIds={setUserIds}
                  fetchUsers={fetchUsers}
                  setTaskComment={setTaskComment}
                  setNewTaskId={setNewTaskId}
                  addComment={addComment}
                  onDiscardComment={onDiscardComment}
                  onFileAttach={onFileAttach}
                  removeAttachment={removeAttachment}
                  uploadLoader={uploadLoader}
                  commentsLoading={commentsLoading}
                  taskTcinComments={taskTcinComments}
                  taskId={taskDetails && taskDetails.task_detail.task_uuid}
                  commentsList={taskDetails && taskDetails.task_comments}
                  commentsOnLoad={commentsOnLoad}
                  commentFile={commentFile}
                />
              </Grid>
            </TaskBlock>

            {/* <TcinDetailsLabel
            label='Assigned Artist: '
            value={
              artistId ? (
                <span>
                  <CustomAvatar
                    isCard
                    data={{
                      displayName: artistName || 'N.A',
                      loginId: artistId
                    }}
                    customClasses={classes.avatar}
                  />
                  <span style={{ marginLeft: 35 }}>{artistName || 'NA'}</span>
                </span>
              ) : (
                'NA'
              )
            }
            type='string'
          /> */}
            {/* <TcinDetailsLabel
            label='Assigned Reviewer: '
            value={
              reviewerId ? (
                <span>
                  <CustomAvatar
                    isCard
                    data={{
                      displayName: reviewerName || 'N.A',
                      loginId: reviewerId
                    }}
                    customClasses={classes.avatar}
                  />
                  <span style={{ marginLeft: 35 }}>{reviewerName || 'NA'}</span>
                </span>
              ) : (
                'NA'
              )
            }
            type='string'
          /> */}
            {/* <Grid
            container
            justify='center'
            className={classes.commentsContainer}
          >
            <TaskComments commentsList={sampleData.task_comments} taskID={sampleData.task_detail.task_uuid} />
          </Grid> */}
          </MainContent>
        )}
      </DrawerContent>
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        BackdropProps={{ invisible: true }}
        variant="persistent"
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={() => {}}
        open={openDrawer}
      >
        {drawer()}
      </Drawer>
    </div>
  )
}

TaskSummary.propTypes = {
  window: PropTypes.func,
}

export default TaskSummary
