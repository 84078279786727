import { storageSet } from '../../utils/storage'
import {
  createPopupWindow,
  getTokensFromHash,
  PopUpOptions,
} from '../../utils/urls'
import isDevEnv from '../../utils/isDevEnv'
import { PRAXIS_REDIRECT_AFTERAUTH } from '../../constants'

import { OnLoginCallback } from '../AuthenticationProps'

export interface LoginParams<Url extends string = string> {
  redirect: Url
  loginUrl: Url
}

export type FullPageLoginParams = LoginParams

export const fullPageLogin = ({
  redirect,
  loginUrl,
}: FullPageLoginParams): void => {
  // save current route
  if (redirect) {
    storageSet(PRAXIS_REDIRECT_AFTERAUTH, redirect)
  }
  // redirect to OAuth login page
  window.location.replace(loginUrl)
}

export type SaveSession = (
  accessToken: string,
  identityToken: string,
  setStorage: boolean,
  redirect: string
) => Promise<void>

export interface PopUpLoginParams<Url extends string = string>
  extends LoginParams {
  onLogin: OnLoginCallback
  saveSession: SaveSession
  loginRedirect: Url
  popUpOptions?: PopUpOptions
}

export const popUpLogin = ({
  popUpOptions,
  loginUrl,
  onLogin,
  saveSession,
  loginRedirect,
  redirect,
}: PopUpLoginParams): void => {
  // open up a popup with promise
  const popupWindow = createPopupWindow(loginUrl, popUpOptions)
  if (!popupWindow) {
    if (isDevEnv()) {
      console.error(
        'ERROR: window.open() did not return a handle. The login flow may not complete. See https://praxis.prod.target.com/authentication/#ie11-quirk for more information.'
      )
    }
  } else {
    // logic for polling the popup window for login information
    const polling = setInterval(async () => {
      // check for premature closure of popup
      if (
        !popupWindow ||
        popupWindow.closed ||
        popupWindow.closed === undefined
      ) {
        clearInterval(polling)
        onLogin(
          new Error('The auth window was closed before authenticating.'),
          null
        )
      }
      try {
        // if we were redirected back to our app we can assume tokens are present
        if (popupWindow.location.href.split('#')[0] === loginRedirect) {
          clearInterval(polling)

          if (popupWindow.location.hash.length) {
            const { accessToken, identityToken } =
              getTokensFromHash(popupWindow)
            if (accessToken && identityToken) {
              await saveSession(accessToken, identityToken, true, redirect)
            }
          } else {
            onLogin(
              new Error(
                'OAuth redirect has occurred but no query or hash parameters were found.'
              ),
              null
            )
          }
          // cleanup
          popupWindow.close()
        }
      } catch (error) {
        // try/catch IE workaround since IE will throw an error here. We just swallow the error
      }
    }, 500)
  }
}
