import React, { memo, useEffect, useCallback, ReactNode } from 'react'
import {
  clearAllStorage,
  getNonce,
  getRedirect,
  setKeys,
  storageClear,
  storageGet,
} from '../utils/storage'
import {
  getTokensFromHash,
  shouldSendHeaders as shouldSendHeadersUtil,
} from '../utils/urls'
import { matchesCookie } from '../utils/cookies'
import { removeBearer } from '../utils/format'

import { ACCESS_TOKEN, ID_TOKEN, PRAXIS_REDIRECT_AFTERAUTH } from '../constants'
import Session from '../Session'
import { AuthContext } from '../context'

import Loading from './Loading'
import useLogin from './useLogin'
import useLogout from './useLogout'
import AuthInterceptor from './AuthInterceptor'
import useAuthState from './useAuthState'
import { SaveSession } from './useLogin/login'
import generateAPI from './generateAPI'
import RenderChildren from './RenderChildren'
import { AuthenticationBaseProps } from './AuthenticationProps'
import deprecationWarnings from './deprecationWarnings'

type RenderProp<P extends any> = (api: P) => ReactNode
/** Props for Authentication component */
export interface AuthenticationProps extends AuthenticationBaseProps {
  children?: RenderProp<AuthContext> | ReactNode
}

/**
 * React component for managing OAuth flow authentication.
 * On startup it checks localStorage and the URL hash for valid tokens then passes on those tokens to its children and through the `onLogin` callback.
 * Holds the current Session as state and provides it as an API along with other authentication actions that can be taken such as `login` and `logout`.
 * This component will also create an Axios interceptor that will by default inject a Bearer token to all Target domains.
 *
 * @summary
 * React component for managing OAuth flow authentication.
 *
 * @component
 * @example
 *    <Authentication clientId="your_id2_client_id" onLogin={(error, session) => { if (session) console.log('user logged in!')}} />
 * @example
 *    <Authentication clientId="your_id2_client_id">
 *      {({ login }) => <button onClick={() => login()}>Click here to log in!</button>}
 *    </Authentication
 */
const Authentication = memo(
  ({
    accessTokenKey = ACCESS_TOKEN,
    doesSSOCookieMatch = matchesCookie,
    popUp = false,
    loadingIndicator: LoadingC = Loading,
    onLogin = () => {},
    onLogout = () => {},
    noHeaders = false,
    shouldSendHeaders = shouldSendHeadersUtil,
    hideLoadingIndicator = false,

    // config
    authorizationUrl = 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    loginRedirect = typeof window === 'undefined'
      ? ''
      : `${window.location?.origin ?? ''}`,
    logoutUrl = 'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    popUpOptions = { width: 482, height: 680 },
    responseType = 'token id_token',
    scope = ['openid profile'],
    storageType = 'localStorage',
    tokenType = 'Bearer',
    clientId,
    SSOCookieName,
    extraUserInfoFields,
    nonce,
    logoutRedirect,
    children,
  }: AuthenticationProps) => {
    const {
      canRender,
      loginAction,
      loginErrorAction,
      logoutAction,
      blockingCallback,
      session,
    } = useAuthState({ onLogin, onLogout })

    // Helper for saving session from token information
    // 1. checks validity
    // 2. formats information
    // 3. saves tokens to localStorage (optional)
    // 4. calls onLogin callback and sets state
    // 5. redirect page (optional)
    const saveSession: SaveSession = useCallback(
      async (
        accessToken,
        identityToken = '',
        setStorage = false,
        redirect = ''
      ) => {
        const session = new Session(accessToken, identityToken, {
          accessTokenType: tokenType,
          authorizationUrl,
          clientId,
          nonce: getNonce(),
        })
        let valid = await session.validate()
        if (SSOCookieName) {
          valid = doesSSOCookieMatch(SSOCookieName, session)
        }
        if (valid) {
          if (setStorage && storageType === 'localStorage') {
            setKeys(accessToken, accessTokenKey, tokenType, identityToken)
          }

          if (redirect.length) {
            blockingCallback(() => {
              storageClear(PRAXIS_REDIRECT_AFTERAUTH)
              window.location.replace(redirect)
            })
          } else {
            loginAction(session)
          }
        } else {
          clearAllStorage(accessTokenKey)
          loginErrorAction(
            new Error(
              'Failed to validate tokens. They have either expired, were not parsed correctly, or did not pass the Single sign-on check.'
            )
          )
        }
      },
      [
        tokenType,
        authorizationUrl,
        clientId,
        SSOCookieName,
        doesSSOCookieMatch,
        storageType,
        accessTokenKey,
        blockingCallback,
        loginAction,
        loginErrorAction,
      ]
    )

    // Check for cases where we are already logged in either:
    // - tokens are already present in localStorage
    // - tokens are in the address bar
    useEffect(() => {
      // don't try to log in if we already have it
      // 0. Check if deprecated props are given and warn!
      deprecationWarnings({ nonce, extraUserInfoFields })
      // 1. check for localStorage
      let accessToken = storageGet(accessTokenKey)
      let identityToken = storageGet(ID_TOKEN)
      let redirect = ''
      let setStorage: boolean = storageType === 'localStorage'

      // 2. check for tokens in URL if not found in localStorage
      if (!accessToken && !popUp && window.location.hash.length) {
        const tokens = getTokensFromHash()
        accessToken = tokens.accessToken
        identityToken = tokens.identityToken

        //redirect to the original location if present
        window.history.replaceState(
          null,
          '', // most browsers currently ignore this so it is recommended to put an empty string
          window.location.href.split('#')[0]
        )
        redirect = getRedirect() || ''
      }

      if (accessToken) {
        accessToken = removeBearer(accessToken)
        // if tokens were found, log in as them if they are valid
        saveSession(accessToken, identityToken || '', setStorage, redirect)
      } else {
        // we are entering the app anew
        // --- or ---
        // in the case where we are logging in but have failed isFullPageAuth but
        // we still have a hash, we are probably in the popup redirect and should let login
        // handle grabbing the tokens instead. In that case we do not want to clear nonce
        // because we have yet to do any verification and we want to clear redirect
        // because login will be passing that through
        window.location.hash.length
          ? storageClear(PRAXIS_REDIRECT_AFTERAUTH)
          : clearAllStorage(accessTokenKey)
        // 3. allow login / logout
        loginAction(null)
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps -- only want to run once

    // logging in
    const login = useLogin({
      accessTokenKey,
      authorizationUrl,
      onLogin,
      popUp,
      popUpOptions,
      loginParams: {
        clientId,
        responseType,
        scope,
        tokenType,
        loginRedirect,
      },
      saveSession,
    })

    // logging out
    const logout = useLogout({
      accessTokenKey,
      logoutRedirect,
      logoutUrl,
      popUp,
      popUpOptions,
      logoutAction,
    })

    // no user info or login checks yet, don't try to render anything
    if (!canRender) {
      return !hideLoadingIndicator ? <LoadingC /> : null
    }

    return (
      <>
        {session && !noHeaders && (
          <AuthInterceptor
            session={session}
            shouldSendHeaders={shouldSendHeaders}
            tokenType={tokenType}
          />
        )}
        <RenderChildren
          api={generateAPI({
            session,
            SSOCookieName,
            doesSSOCookieMatch,
            login,
            logout,
            popUp,
          })}
          children={children}
        />
      </>
    )
  }
)

export default Authentication
