import Moment from 'moment'
import MomentTM from 'moment-timezone'

export const formatDate = function (date = '', delimiter = '-') {
  if (!date) {
    return date || ''
  }
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join(delimiter)
}

export const millisToMinutesAndSeconds = function (millis) {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  return minutes + '.' + (seconds < 10 ? '0' : '') + seconds
}

export const getDisplayDate = function (
  dateParam,
  isAlreadyCst = false,
  format = 'DD/MM/YYYY HH:mm:ss z'
) {
  if (Moment(dateParam).isValid()) {
    if (isAlreadyCst) {
      return Moment(dateParam).utc().format(format)
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const time = MomentTM.tz(dateParam, 'America/chicago').format()
    const timeUpdatedForTimeZone = MomentTM.tz(time, timeZone).format(format)
    return timeUpdatedForTimeZone
  }
  return ''
}
