/** @module harbinger */
import 'core-js/fn/array/includes'
import 'core-js/fn/object/define-property'
import 'core-js/fn/promise'
import Harbinger from './Harbinger'

/*
  This library exports a single instance of the harbinger class
  so that all imports refer to the same instance.
*/
const instance = new Harbinger()
export default instance
// Here so the window export has a nicer name than default off the harbinger property (i.e. window.harbinger.client versus window.harbinger.default)
export const client = instance
