import 'babel-polyfill'
import { AppConfig, CustomEvent, ServiceErrorEvent, ViewEvent } from './models'
import eventProcessor, { EventMessage } from './eventProcessor'
import constants from './constants'

/**
 * Sends an Event and AppConfig to the eventProcessor. Catches errors
 * and wraps them in an unhandled rejected promise: This allows us to
 * surface errors without blocking the calling UI.
 * @param {Array} eventParams An array of arguments to send to the event constructor.
 * @param {EventType} Class An Event class. Constructs the event with the eventParams.
 * @param {Object} appConfig An object of params to send to AppConfig.
 * @returns {Promise}
 */
function track (eventParams, EventType, appConfig) {
  try {
    const event = new EventType(...eventParams)
    const config = new AppConfig(appConfig)
    return eventProcessor.processEvent(new EventMessage(event, config))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(constants.ERROR_MESSAGES.TRACK_PARAMETERS_INVALID)
    return Promise.reject(e)
  }
}

/**
 * The Harbinger class allows you to globally set configuration
 * options, and exposes methods for tracking events.
 *
 * @memberof module:harbinger
 * @property {string} appId - Your app's Harbinger ID. Required by the Harbinger API.
 * @property {string} apiKey - Your app's API Gateway key used for communicating with the Harbinger API.
 * @property {string} segment - The segment to which the visitor belongs. Required by the Harbinger API. (Note: AppConfig provides a default value.)
 * @property {string} visitorId - The visitor's user ID. Required by the Harbinger API.
 */
class Harbinger {
  constructor () {
    // These params are required to send any events to the Harbinger API.
    this.appId = undefined
    this.apiKey = undefined
    this.segment = undefined
    this.visitorId = undefined
  }

  /**
   * @member {Object} config - The required API params, derived from the current property values.
   */
  get config () {
    return {
      appId: this.appId,
      apiKey: this.apiKey,
      segment: this.segment,
      visitorId: this.visitorId,
    }
  }

  /**
   * Tracks a custom event.
   * @param {string} customEventId - The ID of an event that exists in the Harbinger back end.
   * @param {Array} [customDataPoints] - An array of objects in format [{key: {string}, value: {string}, numeric_value: {number}}]
   * @returns {Promise}
   */
  trackEvent (customEventId, customDataPoints) {
    return track([customEventId, customDataPoints], CustomEvent, this.config)
  }

  /**
   * Tracks a service error event.
   * @param {string} requestUrl - The URL of the service request that returned the error.
   * @param {number} httpCode - The numeric HTTP status code returned by the service.
   * @returns {Promise}
   */
  trackServiceError (requestUrl, httpCode) {
    return track([requestUrl, httpCode], ServiceErrorEvent, this.config)
  }

  /**
   * Tracks a page view event.
   * @param {string} url
   * @param {string} title
   * @returns {Promise}
   */
  trackView (url, title) {
    return track([url, title], ViewEvent, this.config)
  }
}

export default Harbinger
