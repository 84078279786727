export const ACCESS_TOKEN = 'access_token'
export const CONFIG_KEYS = [
  'authorizationUrl',
  'clientId',
  'logoutUrl',
  'nonce',
  'popupOptions',
  'redirectUri',
  'responseType',
  'scope',
  'storageType',
  'tokenType',
]
export const ID_TOKEN = 'id_token'
export const NONCE = 'NONCE'
export const JWKS = 'ID2_JWKS'
export const PRAXIS_REDIRECT_AFTERAUTH = 'PRAXIS_REDIRECT_AFTERAUTH'
export const ACCESS_DENIED =
  'https://logonservices.iam.target.com/login/responses/accessdenied.html'
