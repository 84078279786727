import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  DialogActions,
  Typography,
  Tooltip,
  Input,
  IconButton,
} from '@material-ui/core'
import { Button, TextField } from '@mui/material'
import { CSVLink } from 'react-csv'
import {
  AddCircleOutline,
  Check,
  CheckCircleOutline,
  CloseOutlined,
  ErrorOutline,
  Clear,
} from '@material-ui/icons'
import './UploadSummary.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'
const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      width: '449px !important',
    },
  },
  dialogTitle: {
    display: 'flex ',
    justifyContent: 'space-between',
    alignItems: 'center ',
    fontSize: '14px',
  },
  icon: {
    fontSize: '18px',
    marginLeft: '50px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapper: {},
  success: {
    backgroundColor: 'rgba(76, 175, 80, 0.08)',
    color: 'rgba(60, 180, 99, 0.7)',
    padding: '8px',
    borderRadius: '4px',
  },
  partial: {
    backgroundColor: 'rgba(252, 222, 197, 1)',
    color: 'rgba(174, 87, 15, 1)',
    padding: '8px',
    borderRadius: '4px',
  },
  message: {
    lineHeight: '18.84px',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  successHighlightMessage: {
    color: 'rgba(60, 180, 99, 1)',
  },
  mainContent: {
    marginTop: '14px',
  },
  subTitle: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '21px',
    width: '305px',
  },
  summaryWrapper: {
    borderWidth: '0.5px 0px',
    borderStyle: 'solid',
    borderColor: 'rgba(136, 136, 136, 1)',
    marginTop: '8px',
  },
  tableFonts: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '157%',
  },
  table: {
    marginLeft: '-4px',
    marginTop: '5px',
  },
  errorFont: {
    color: '#CC0000',
  },
  failed: {
    backgroundColor: 'rgba(172, 0, 0, 0.08)',
    color: 'rgba(204, 0, 0, 0.8)',
    padding: '8px',
    borderRadius: '4px',
  },
  failedHighlightMessage: {
    color: 'rgba(204, 0, 0, 1)',
  },
  accordion: {
    backgroundColor: 'rgba(232, 232, 232, 1) !important',
    padding: '0 14px !important',
    height: '36px',
    minHeight: '48px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  accordionSumary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      fontWeight: 500,
      fontSize: '14px',
    },
  },
  accordionDetails: {
    backgroundColor: 'rgba(232, 232, 232, 1)',
    overflowY: 'scroll',
    height: '156px',
    '& .MuiTypography-root': {
      fontWeight: 500,
      fontSize: '14px',
    },
    padding: '0px !important',
  },
  fileListContainer: {
    borderTop: '1px solid #8B8F98',
    padding: '10px',
  },
  filesList: {
    textDecoration: 'underline',
    wordWrap: 'break-word',
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: '8px',
    },
  },
  downloadReport: {
    marginTop: '14px',
  },
  secondaryButton: {
    border: '1px solid #3D70D6',
    color: '#3D70D6',
  },
  primaryButton: {
    background: '#3D70D6',
    color: '#fff',
    '&:hover': {
      background: 'rgba(61, 112, 214, 0.8)',
    },
  },
  actionButton: {
    marginRight: '15px',
  },
  csvLink: {
    fontWeight: '300',
    fontSize: '13px',
  },
  iconsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  countIcon: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    background: '#3D70D6',
    marginRight: '10px',
    display: 'flex',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    marginLeft: '10px',
  },
  tickIcon: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    background: '#32864E',
    marginRight: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  crossIcon: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    background: '#AE570F',
    marginRight: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '54px',
    border: '1px solid #737881',
    borderRadius: '24px',
    position: 'relative',
    height: '24px',
  },
  chipIcon: {
    fontSize: '16.5px',
    color: 'white',
  },
  countSpan: {
    marginLeft: '2px',
    marginRight: '2px',
  },
  iconContainer: {
    borderRadius: '24px',
    height: '24px',
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function UploadSummary({
  open = false,
  tcin = '',
  sceneFiles = {},
  referenceFiles = {},
  onClose = () => {},
  refrenceCount = 0,
  sceneCount = 0,
  titleMessages = '',
  isSuccess,
  isPartial,
  reportLinkName = '',
  reportData = [],
  reportName = '',
  retry = () => {},
  handleRequest = (value) => {},
  isDisable = false,
}) {
  const [missingPath, setOpenInputForMissingPath] = useState('')

  const [missingPathInput, setMissingPathInput] = useState('')

  const [copyPaste, setCopyPaste] = useState(false)

  const [copied, setCopied] = useState(null)

  const classes = useStyles()

  const handleCopyPaste = (name) => {
    setCopied(name)
  }

  const missingSceneFile =
    sceneFiles && sceneFiles.missing ? sceneFiles.missing : []

  const presentSceneFile =
    sceneFiles && sceneFiles.present ? sceneFiles.present : []

  const missingReferenceFiles =
    referenceFiles && referenceFiles.missing ? referenceFiles.missing : []

  const presentReferenceFiles =
    referenceFiles && referenceFiles.present ? referenceFiles.present : []

  const conditionList = sceneCount !== 0 || refrenceCount !== 0

  const presentFileChip = (count = 0, icon = '') => {
    return (
      <Tooltip title={icon + ' ' + 'Files'}>
        <div className={classes.chipStyle}>
          <div
            style={
              icon === 'Present'
                ? {
                    background: '#32864E',
                  }
                : {
                    background: '#AE570F',
                  }
            }
            className={classes.iconContainer}
          >
            {icon === 'Present' ? (
              <CheckCircleOutline className={classes.chipIcon} />
            ) : (
              <ErrorOutline className={classes.chipIcon} />
            )}
          </div>
          <span className={classes.countSpan}>{count}</span>
        </div>
      </Tooltip>
    )
  }

  const handleClear = () => {
    setMissingPathInput('')
    setOpenInputForMissingPath('')
  }

  const handleSubmit = (input) => {
    handleRequest(input)
    handleClear()
  }
  return (
    <Dialog open={open} className={classes.dialog} maxWidth="sm">
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          Automated .zip file creation for {tcin}
          <CloseOutlined className={classes.icon} onClick={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent id="modal-content">
        <Box
          className={
            isSuccess
              ? classes.success
              : isPartial
              ? classes.partial
              : classes.failed
          }
        >
          <Typography className={classes.message}>
            <span
              className={
                titleMessages
                  ? isSuccess
                    ? classes.successHighlightMessage
                    : isPartial
                    ? classes.partialHighlightMessage
                    : classes.failedHighlightMessage
                  : ''
              }
            >
              {titleMessages}
            </span>
          </Typography>
        </Box>
        <Box className={classes.downloadReport}>
          <CSVLink
            target="_blank"
            data={reportData}
            filename={`${reportName}.csv`}
            className="csv-link"
          >
            <Typography className={classes.csvLink}>
              {reportLinkName}
            </Typography>
          </CSVLink>
        </Box>
        {conditionList && (
          <Typography
            variant="body1"
            style={{ fontSize: '14px', margin: '10px 0 0 0' }}
          >
            List of files uploaded
          </Typography>
        )}
        {sceneCount !== 0 && (
          <Accordion sx={{ mt: 1, minHeight: '42px' }}>
            <AccordionSummary
              className={classes.accordion}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                Scene files
                <div className={classes.iconsWrapper}>
                  <div className={classes.countIcon}>{sceneCount}</div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '230px',
                      gap: '5px',
                    }}
                  >
                    {presentFileChip(
                      presentSceneFile && presentSceneFile.length
                        ? presentSceneFile.length
                        : 0,
                      'Present'
                    )}
                    {presentFileChip(
                      missingSceneFile && missingSceneFile.length
                        ? missingSceneFile.length
                        : 0,
                      'Missing'
                    )}
                  </div>
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetails}>
              {[...missingSceneFile].map((name, index) => (
                <div className={classes.fileListContainer}>
                  <Typography
                    key={index}
                    className={classes.filesList}
                    style={{
                      color:
                        missingSceneFile.length >= index + 1
                          ? '#AE570F'
                          : 'black',
                    }}
                    onMouseLeave={() => setCopied(null)}
                  >
                    <Tooltip
                      arrow
                      title={copied === name ? 'Copied' : 'Copy Path'}
                    >
                      <CopyToClipboard
                        text={name}
                        onCopy={() => setCopyPaste(true)}
                      >
                        <span
                          onClick={() => handleCopyPaste(name)}
                          style={copied === name ? { color: '#1976d2' } : {}}
                        >
                          {name}
                        </span>
                      </CopyToClipboard>
                    </Tooltip>
                  </Typography>
                  {name !== missingPath && (
                    <Button
                      sx={{
                        padding: 0,
                        margin: '6px 0',
                        textTransform: 'capitalize',
                      }}
                      onClick={() => setOpenInputForMissingPath(name)}
                    >
                      <AddCircleOutline style={{ marginRight: '6px' }} />
                      Add Path
                    </Button>
                  )}
                  {name === missingPath && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'end',
                        marginTop: '10px',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        sx={{
                          padding: 0,
                        }}
                        size="small"
                        fullWidth
                        value={missingPathInput}
                        onChange={(e) => setMissingPathInput(e.target.value)}
                      />
                      <IconButton
                        onClick={() => handleSubmit(missingPathInput)}
                        style={{ padding: 0 }}
                      >
                        <Check />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClear()}
                        style={{ padding: 0 }}
                      >
                        <Clear />
                      </IconButton>
                    </div>
                  )}
                </div>
              ))}
              {[...presentSceneFile].map((item, index) => {
                return (
                  <div className={classes.fileListContainer}>
                    <Typography
                      key={index}
                      className={classes.filesList}
                      style={{
                        color: 'black',
                      }}
                      onMouseLeave={() => setCopied(null)}
                    >
                      <Tooltip
                        arrow
                        title={copied === item ? 'Copied' : 'Copy Path'}
                      >
                        <CopyToClipboard
                          text={item}
                          onCopy={() => setCopyPaste(true)}
                        >
                          <span
                            onClick={() => handleCopyPaste(item)}
                            style={copied === item ? { color: '#1976d2' } : {}}
                          >
                            {item}
                          </span>
                        </CopyToClipboard>
                      </Tooltip>
                    </Typography>
                  </div>
                )
              })}
            </AccordionDetails>
          </Accordion>
        )}

        {refrenceCount !== 0 && (
          <Accordion sx={{ mt: 1, minHeight: '42px' }}>
            <AccordionSummary
              className={classes.accordion}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                Reference files
                <div className={classes.iconsWrapper}>
                  <div className={classes.countIcon}>{refrenceCount}</div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '203px',
                      gap: '5px',
                    }}
                  >
                    {presentFileChip(
                      presentReferenceFiles && presentReferenceFiles.length
                        ? presentReferenceFiles.length
                        : 0,
                      'Present'
                    )}
                    {presentFileChip(
                      missingReferenceFiles && missingReferenceFiles.length
                        ? missingReferenceFiles.length
                        : 0,
                      'Missing'
                    )}
                  </div>
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetails}>
              {[...missingReferenceFiles].map((name, index) => (
                <div className={classes.fileListContainer}>
                  <Typography
                    key={index}
                    className={classes.filesList}
                    style={{
                      color:
                        missingReferenceFiles.length >= index + 1
                          ? '#AE570F'
                          : 'black',
                    }}
                    onMouseLeave={() => setCopied(null)}
                  >
                    <Tooltip
                      arrow
                      title={copied === name ? 'Copied' : 'Copy Path'}
                    >
                      <CopyToClipboard
                        text={name}
                        onCopy={() => setCopyPaste(true)}
                      >
                        <span
                          onClick={() => handleCopyPaste(name)}
                          style={copied === name ? { color: '#1976d2' } : {}}
                        >
                          {name}
                        </span>
                      </CopyToClipboard>
                    </Tooltip>
                  </Typography>
                  {name !== missingPath && (
                    <Button
                      sx={{
                        padding: 0,
                        margin: '6px 0',
                        textTransform: 'capitalize',
                      }}
                      onClick={() => setOpenInputForMissingPath(name)}
                    >
                      <AddCircleOutline style={{ marginRight: '6px' }} />
                      Add Path
                    </Button>
                  )}
                  {name === missingPath && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'end',
                        marginTop: '10px',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        sx={{
                          padding: 0,
                        }}
                        size="small"
                        fullWidth
                        value={missingPathInput}
                        onChange={(e) => setMissingPathInput(e.target.value)}
                      />
                      <IconButton
                        onClick={() => handleSubmit(missingPathInput)}
                        style={{ padding: 0 }}
                      >
                        <Check />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClear()}
                        style={{ padding: 0 }}
                      >
                        <Clear />
                      </IconButton>
                    </div>
                  )}
                </div>
              ))}
              {[...presentReferenceFiles].map((item, index) => {
                return (
                  <div className={classes.fileListContainer}>
                    <Typography
                      key={index}
                      className={classes.filesList}
                      style={{
                        color: 'black',
                      }}
                      onMouseLeave={() => setCopied(null)}
                    >
                      <Tooltip
                        arrow
                        title={copied === item ? 'Copied' : 'Copy Path'}
                      >
                        <CopyToClipboard
                          text={item}
                          onCopy={() => setCopyPaste(true)}
                        >
                          <span
                            onClick={() => handleCopyPaste(item)}
                            style={copied === item ? { color: '#1976d2' } : {}}
                          >
                            {item}
                          </span>
                        </CopyToClipboard>
                      </Tooltip>
                    </Typography>
                  </div>
                )
              })}
            </AccordionDetails>
          </Accordion>
        )}
      </DialogContent>
      <DialogActions className={classes.actionButton}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>

        {!isSuccess && (
          <Button variant="outlined" onClick={retry} disabled={isDisable}>
            Retry
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default UploadSummary

// #32864E
