import React, { ReactNode } from 'react'
import Authentication from './Authentication'
import { AuthenticationBaseProps } from './Authentication/AuthenticationProps'
import AuthCtx from './context'

/** Props for AuthProvider */
export interface AuthenticationProviderProps extends AuthenticationBaseProps {
  children: ReactNode
}
/**
 * React component that renders the Provider portion of the AuthContext and sets its value when the authentication check as been completed.
 * Should be used as the root component of your application so every component has access to the context's Consumer.
 *
 * @see Authentication
 * @component
 * @example
 *    <AuthProvider clientId="your_id2_client_id">
 *      <App />
 *    </AuthProvider>
 */
const AuthenticationProvider = ({
  children,
  ...props
}: AuthenticationProviderProps) => {
  return (
    <Authentication {...props}>
      {(api) => <AuthCtx.Provider value={api}>{children}</AuthCtx.Provider>}
    </Authentication>
  )
}

export default AuthenticationProvider
