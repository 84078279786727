/**
 * Data formatted as expected by the Habringer API for a custom data point
 * @memberOf module:harbinger/models
 */
class CustomDataPoint {
  /**
   * @param {string} key
   * @param {string} [value=null]
   * @param {number} [numeric_value=null]
   * @returns {{key: {string}, value: {string}, numeric_value: {number}}}
   */
  constructor (key, value = null, numeric_value = null) {
    return { key, value, numeric_value }
  }
}

export default CustomDataPoint
