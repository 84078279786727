import { createContext, Context } from 'react'

import Session from './Session'

/**
 * An object passed around when a function requires that a redirect is honored
 */
export interface RedirectParam<Path extends string = string> {
  redirect: Path
}

/**
 * A function that takes in a redirect that should be honored after authentication happens
 */
export type LoginHandler = (loginParams?: RedirectParam) => void

/**
 * API broadcasted by the AuthContext value
 */
export interface AuthContext {
  /** function that checks whether the current user is logged in or not */
  isAuthenticated: () => boolean
  /**
   * function that checks whether the current user has at least one of the provided groups
   * @note members of `groups` must be the common name (CN) value only
   * @note users is authorized if they have *at least* one of the listed allowed AD groups
   * @note providing an empty array will check for authentication only
   * @param groups AD groups to check authorization against (default: an empty array)
   */
  isAuthorized: (groups?: string[]) => boolean
  /** function that returns whether Praxis auth was set up as a full page login or not (is a popup login flow) */
  isFullPageAuth: () => boolean
  /** function that triggers the login flow */
  login: LoginHandler
  /** function that triggers the logout flow */
  logout: () => void
  /**
   * current user's session
   * @see {Session}
   */
  session: Session | null
  /** set to true if there is no provider detected */
  noProvider?: boolean
}

const AuthContextError =
  new Error(`This function was called outside the bounds of an <AuthProvider>.
For more details see:
https://praxis.prod.target.com/components/authentication#auth-provider`)

// default for `createContext` is only used when consumer does not have a provider
const defaultWarningObject: AuthContext = {
  isAuthenticated: () => {
    throw AuthContextError
    return false // eslint-disable-line no-unreachable
  },
  isAuthorized: () => {
    throw AuthContextError
    return false // eslint-disable-line no-unreachable
  },
  isFullPageAuth: () => {
    throw AuthContextError
    return false // eslint-disable-line no-unreachable
  },
  login: () => {
    throw AuthContextError
  },
  logout: () => {
    throw AuthContextError
  },
  get session() {
    throw AuthContextError
    return null //eslint-disable-line no-unreachable
  },
  noProvider: true,
}

/** React context for storing authentication information and functions */
const AuthCtx: Context<AuthContext> =
  createContext<AuthContext>(defaultWarningObject)

export default AuthCtx
