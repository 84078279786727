import React, { ReactNode } from 'react'

type RenderProp<P> = (api: P) => ReactNode
type RenderChildrenType<P> =
  | {
      api: P
      children: RenderProp<P>
    }
  | {
      api?: P
      children: ReactNode
    }

const RenderChildren = <T extends any>(
  props: React.PropsWithChildren<RenderChildrenType<T>>
) => {
  const { children, api } = props
  if (children && isFunction(children) && api) {
    return <>{children(api)}</>
  }
  return <>{children}</>
}

type IsFunction<T> = T extends (...args: any[]) => any ? T : never
const isFunction = <T extends {}>(value: T): value is IsFunction<T> =>
  typeof value === 'function'

export default RenderChildren
