/* eslint-disable default-case */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Moment from 'moment'

const styles = (theme) => ({
  chip: {
    marginLeft: 2,
    color: theme.palette.primary.light,
    height: 21,
    fontSize: '11px',
  },
  label: {
    fontSize: 12,
    color: '#999',
  },
  value: {
    fontSize: 12,
    color: '#000',
    marginLeft: '2px',
  },
  prodDescriptionText: {
    fontSize: 12,
    color: theme.palette.primary.darkFont,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: ' -webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    margin: '-14px 0 0 112px',
  },
  container: {
    height: 'auto',
  },
  anchor: {
    color: theme.palette.primary.linkColor,
    cursor: 'pointer',
  },
  contentSpacing: {
    margin: '0 0 10px 0',
    width: '100%',
  },
  iconSize: {
    fontSize: '14px',
  },
})

const TcinDetailsLabel = (props) => {
  const {
    classes,
    label,
    value,
    type,
    isHtml,
    decodeHtml,
    onClick = () => {},
  } = props
  let returnValue = ''
  if (value && type) {
    let childNode = ''
    switch (type) {
      case 'string':
        childNode = (
          <div>
            <span className={classes.label}>{label}</span>
            {isHtml ? (
              <div
                className={classes.prodDescriptionText}
                dangerouslySetInnerHTML={decodeHtml(value)}
              />
            ) : (
              <span className={classes.value}>{value}</span>
            )}
          </div>
        )
        break
      case 'date':
        childNode = (
          <div>
            <span className={classes.label}>{label}</span>
            <span className={classes.value}>
              {Moment(value).format('MM/DD/YYYY')}
            </span>
          </div>
        )
        break
    }
    returnValue = (
      <div
        role="button"
        tabIndex={0}
        className={classes.contentSpacing}
        onClick={onClick}
        onKeyDown={onClick}
      >
        {childNode}
      </div>
    )
  }
  return returnValue
}

export default withStyles(styles)(TcinDetailsLabel)
