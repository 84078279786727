import {checkStringIsNonEmpty, getCreationFailedMessage} from '../validate'
import constants from './constants'
import CustomDataPoint from './CustomDataPoint'
import Event from './Event'

/**
 * Event for viewing a particular URL.
 * @memberOf module:harbinger/models
 */
class ViewEvent extends Event {
  /**
   * Constructs the necessary custom data points using the given URL and title.
   * If the construction of the object is unsuccessful, it returns an empty object.
   * @param url {string} - URL of the page viewed which becomes the "view_url" custom data point.
   * @param title {string} - Title of the page viewed which becomes the "view_title" custom data point.
   * @returns {ViewEvent|{}}
   */
  constructor (url, title) {
    super({ eventType: constants.EVENT_TYPES.VIEW })

    this.validate(url, 'url', checkStringIsNonEmpty)
      .and(title, 'title', checkStringIsNonEmpty)
      .done(getCreationFailedMessage('ViewEvent'))

    this.customDataPoints = [
      new CustomDataPoint('view_url', url),
      new CustomDataPoint('view_title', title),
    ]

    Object.freeze(this)
  }
}

export default ViewEvent
