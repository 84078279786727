import React, { CSSProperties } from 'react'

interface LogoProps {
  style: CSSProperties
}
const Logo = ({ style }: LogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 105" style={style}>
    <path
      fill="#3A1419"
      d="
  M46.5,1.5 1.5,9.5 16.75,84.25 46.5,103.75 76.25,84.25 91.5,9.5
  M46.5,50.5 m0,-8 a8,8 0 0,1 0,16 a8,8 0 0,1 0,-16
  M46.5,50.5 m0,-24 a24,24,0 0,1 0,48 a24,24,0 0,1 0,-48 M46.5,50.5 m0,-16 a16,16,0 1,0 0,32 a16,16,0 1,0 0,-32
"
    />
  </svg>
)

const Loading = () => {
  return (
    <Logo
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '48px',
      }}
    />
  )
}

export default Loading
