import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { FormControl, Select, MenuItem, InputBase } from '@material-ui/core'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}))

function PhaseDropdown(props) {
  const { selectedPhase, dropdownList, handlePhaseChange } = props
  const classes = useStyles()

  const onPhaseChange = (event) => {
    handlePhaseChange(event.target.value)
  }

  //Comment for a while later should uncomment when we will have roles api
  //   const isLeadForPhase = (phase) => {
  //     return userRoles.user_roles.some((role = '') => {
  //       return (
  //         phase &&
  //         role &&
  //         phase.toLowerCase().replace(' ', '_') + '_lead' === role.toLowerCase()
  //       )
  //     })
  //   }

  return (
    <FormControl className={classes.margin}>
      <Select
        value={selectedPhase}
        onChange={onPhaseChange}
        input={<BootstrapInput name="phase" id="phase" />}
      >
        {_.map(dropdownList, ({ phase_id: phaseId, phase_name: phaseName }) => (
          <MenuItem key={phaseId} className={classes.menuItem} value={phaseId}>
            {phaseName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

PhaseDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default PhaseDropdown
