import { ACCESS_TOKEN, ID_TOKEN } from '../constants'
import isDevEnv from './isDevEnv'
import { getNonce } from './storage'
import { AccessTokenType } from './tokens'

/*
    _______________________________________________
   | URLS                                          |
   |_______________________________________________|
   | Helper methods for URL string manipulation    |
   |_______________________________________________|
*/

/**
 * Return whether or not a provided url points to a Target domain
 * @param url - the url to check
 * @returns true if it does, false if it does not
 */
export const isTargetDomain = (url: string): boolean =>
  /^([^/]+:)?\/{2,3}[^/]+?(\.target\.com|\.tgt)(:|\/|$)/i.test(url)

/**
 * A function that describes when an access token should be attached to web traffic to the given URL via the Authorization header
 * @param url - the URL that is being sent web traffic
 * @returns whether or not to attach a bearer token to the request to the given URL
 */
export type ShouldSendHeaders = (url: string) => boolean
export const shouldSendHeaders: ShouldSendHeaders = (url) => {
  const result = isTargetDomain(url)
  if (!result) warnUnappliedLocalHeaders(url)
  return result
}

const isLocalUrl = (url: string): boolean => {
  try {
    const hostname = (
      createURLObject(url) || { hostname: '' }
    ).hostname.toLowerCase()
    return ['127.0.0.1', 'localhost'].includes(hostname)
  } catch (err) {
    if (isDevEnv()) {
      console.error(`Praxis Error: ${url} is not a local URL.`)
    }
    return false
  }
}

// sends a console warning in non-production environments when axios headers were not applied for a localhost/127.0.0.1 URL
var localWarningSent: boolean = false
const warnUnappliedLocalHeaders = (url: string): void => {
  if (!isLocalUrl(url)) return // don't warn for non-local URLs
  if (localWarningSent) return // don't warn more than once
  if (!isDevEnv()) return // don't warn in non-prod
  localWarningSent = true
  const requestUrl = createURLObject(url)
  const warningMessage = `Praxis Warning: Bearer token not applied. (click for details)`
  console.groupCollapsed(warningMessage)
  console.warn(`An authorization header was not automatically applied for request(s) to '${
    requestUrl?.origin || url
  }' because it is not a '*.target.com' or '*.tgt' domain.

More info:
https://praxis.prod.target.com/guides/authentication#sending-tokens-to-a-local-api`)
  console.groupEnd()
}

export const createURLObject = (
  url: string,
  params?: object | undefined
): URL | null => {
  if (!url) return null
  let newUrl: URL
  try {
    newUrl = new URL(url, window.location.origin)
  } catch (e) {
    console.warn(e)
    return null
  }
  // convert to URL safe if given params
  newUrl.searchParams.forEach((value, key) => {
    newUrl.searchParams.set(key, value)
  })

  // add given params, would overwrite any given by URL
  if (params) {
    for (let [key, value] of Object.entries(params)) {
      newUrl.searchParams.set(key, value)
    }
  }

  return newUrl
}

export const createURLString = (url: string, params?: object | undefined) => {
  let newUrl = createURLObject(url, params)
  if (newUrl) return newUrl.toString()
  return null
}

/**
 * Type of object passed as params to `createLoginUrl`
 * @example
 * const params: AuthLoginParams = {
 *    clientId: 'praxis_npe_im',
 *    loginRedirect: `${window.location.origin}/auth/login`,
 *    responseType: 'token id_token',
 *    scope: ['openid profile'],
 *    tokenType: 'Bearer',
 *    nonce: '1234',
 * }
 */
export interface AuthLoginParams {
  /** an OAuth client ID */
  clientId: string
  /** url that ID2 should redirect the user to after a successful log in */
  loginRedirect: string
  /** what ID2 should return when redirected */
  responseType: string
  /** the OAuth scope */
  scope: string[]
  /** type of token to be returned */
  tokenType: AccessTokenType
}

/**
 * Formats an authorization url that will prompt the user for login.
 * @param authorizationUrl - authorization url base
 * @param params           - url parameters to be added onto the `authorizationUrl`
 * @returns the formatted url string or null if there was an error
 */
export const createLoginUrl = (
  authorizationUrl: string,
  params: AuthLoginParams
): string | null => {
  if (!authorizationUrl) return null

  const urlParams = {
    client_id: params.clientId,
    nonce: getNonce(),
    redirect_uri: params.loginRedirect,
    response_type: params.responseType,
    scope: params.scope,
    token_type: params.tokenType,
  }

  return createURLString(authorizationUrl, urlParams)
}

/**
 * Type of object passed as params to `createLogoutUrl`
 * @example
 * const params: AuthLogoutParams = {
 *    logoutRedirect: 'http://localhost:3000',
 * }
 */
export interface AuthLogoutParams {
  /** Url ID2 should redirect to after logging out the user */
  logoutRedirect: string
}
/**
 * Formats an ID2 url that will tell the user they have been logged out
 * @param logoutUrl - logout url base
 * @param params    - url parameters to be added onto the `logoutUrl`
 * @returns the formatted url string or null if there was an error
 */
export const createLogoutUrl = (
  logoutUrl: string,
  params?: AuthLogoutParams | undefined
): string | null => {
  if (!logoutUrl) return null

  let url: string | null
  if (params?.logoutRedirect) {
    url = createURLString(logoutUrl, { target: params.logoutRedirect })
  } else {
    url = createURLString(logoutUrl)
  }

  return url
}

interface TokenCollection {
  accessToken: string | null
  identityToken: string | null
}
export const getTokensFromHash = (w: Window = window): TokenCollection => {
  const hashAsParams = String(w.location.hash).replace(/#/, '?')
  const hash = new URLSearchParams(hashAsParams)

  const accessToken = hash.get(ACCESS_TOKEN)
  const identityToken = hash.get(ID_TOKEN)

  return {
    accessToken,
    identityToken,
  }
}

export const getWellKnownUrl = (authorizationUrl: string): URL | null => {
  const wellKnownURL = createURLObject(authorizationUrl)
  if (!wellKnownURL) return null
  wellKnownURL.pathname = '/.well-known/openid-configuration'
  return wellKnownURL
}

/**
 * Defines the dimensions of a popup window to be created
 */
export interface PopUpOptions {
  /** width of window in pixels */
  width: number
  /** height of window in pixels */
  height: number
}
// creates a popup window given a URL and matches the size of the popupOptions prop
export const createPopupWindow = (
  url: string,
  popupOptions: PopUpOptions = { width: 482, height: 680 }
): Window | null => {
  const width = Math.floor(popupOptions.width)
  const height = Math.floor(popupOptions.height)
  const top = Math.floor(window.screenY + (window.outerHeight - height) / 2.5)
  const left = Math.floor(window.screenX + (window.outerWidth - width) / 2)
  const features = `width=${width},height=${height},top=${top},left=${left}`
  return window.open(url, '_blank', features)
}
