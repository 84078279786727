import jwt from 'jsonwebtoken'
import {
  AccessTokenType,
  UserInfoResponse,
  IdentityBase,
  Identity,
} from './tokens'

/*
    _______________________________________________
   | FORMAT                                        |
   |_______________________________________________|
   | Helper methods for formatting                 |
   |_______________________________________________|
*/

export const removeBearer = (tokenString: string): string =>
  tokenString ? tokenString.replace('Bearer ', '') : tokenString

export const injectBearer = (tokenString: string): string =>
  tokenString && tokenString.includes('Bearer')
    ? tokenString
    : `Bearer ${tokenString}`

export const formatGroup = (memberOf: string[] = []): string[] => {
  return memberOf && memberOf.length
    ? memberOf.map((group) => {
        const cn = /(CN=)([A-Z0-9- ]+)/i.exec(group)
        return cn === null ? '' : cn[2].toUpperCase()
      })
    : []
}

/**
 * A user-friendly format of a user.
 * @example
 * const testUser: UserInfo = {
 *   accessToken: 'Bearer token',
 *   firstName: 'Super',
 *   lastName: 'Mario',
 *   fullName: 'Super Mario',
 *   lanId: 'z1337',
 *   email: 'Super.Mario@nintendo.com',
 *   memberOf: ['TTS-Star-Power', 'APP-Nintendo-Power'],
 * }
 */
export interface UserInfo {
  /** user's authentication token with an optional 'Bearer' prefix */
  accessToken: string
  /** user's email address */
  email: string
  /** user's first name */
  firstName: string
  /** user's last name */
  lastName: string
  /** user's full name, a concatenation of their first name and last name */
  fullName: string
  /** user's Target lanId */
  lanId: string
  /** formatted list of user's associated AD groups with only the group's common name */
  memberOf: string[]
  [key: string]: any // advanced client config
}
/**
 * Type of object used as `options` param in `formatUserInfo`
 * @example
 * const options: UserInfoOptions = {
 *   addFields: ['storeNumber'],
 *   accessTokenType: 'Bearer',
 * }
 */
export interface UserInfoOptions {
  /** type of access token being provided to `formatUserInfo` */
  accessTokenType?: AccessTokenType
  /** when no identity token is provided you can supply your own user information */
  userInfoOverride?: Partial<Identity> & UserInfoResponse
}
/**
 * Formats information provided by OAuth in a user-friendly format.
 * @param accessToken - a validated authentication token supplied by ID2
 * @param idToken     - a validated identity token supplied by ID2
 * @param options     - additional options for formatting the UserInfo such as additional fields to add
 * @returns a user info object based on contents given parameters
 */
export const formatUserInfo = (
  accessToken: string,
  idToken: string,
  options?: UserInfoOptions
): UserInfo => {
  const tokenType = options?.accessTokenType || ''
  const accessTokenPrefixed =
    tokenType === 'Bearer' ? injectBearer(accessToken) : accessToken
  if (idToken) {
    let userInfo: Identity = jwt.decode(idToken, { json: true }) as Identity // decode unsafe, make sure you verify tokens first
    if (userInfo === null) {
      console.warn('Failed to parse id token, using override')
    }

    const extra = omitBaseToken(userInfo)

    let info: UserInfo = {
      accessToken: accessTokenPrefixed,
      email: userInfo.mail,
      firstName: userInfo.firstname,
      lastName: userInfo.lastname,
      fullName: `${userInfo.firstname} ${userInfo.lastname}`,
      lanId: userInfo.samaccountname || options?.userInfoOverride?.lanid || '',
      memberOf: formatGroup(userInfo.memberof),
      expires: userInfo.exp,
      ...extra,
    }

    return info
  }
  if (options?.userInfoOverride) {
    const { lanid, firstname, lastname, mail, memberof } =
      options.userInfoOverride
    return {
      accessToken: accessTokenPrefixed,
      email: mail,
      firstName: firstname,
      lastName: lastname,
      fullName: `${firstname} ${lastname}`,
      lanId: lanid,
      memberOf: formatGroup(memberof),
    }
  }
  return {} as UserInfo // no identity token and no override provided
}

/*
 * TypeScript helper to type-check array that includes all in string literal union type
 * It doesn't check for duplicates but for this use case we only need to assert an exhaustive list
 */
const arrayOfAll =
  <T>() =>
  <U extends T[]>(
    array: U & ([T] extends [U[number]] ? unknown : 'Missing key')
  ) =>
    array

/**
 * Takes ID token payload and strips out known fields, the remainder is advanced config fields configured by the OAuth client
 * @param payload - the ID token payload decoded to JSON
 */
const omitBaseToken = <T extends Identity>(
  payload: T
): Omit<T, keyof IdentityBase> => {
  const copy = { ...payload }
  const baseKeys = arrayOfAll<keyof IdentityBase>()([
    'sub',
    'iss',
    'iat',
    'ass',
    'sut',
    'auth_time',
    'nonce',
    'cli',
    'pro',
    'sky',
    'jti',
    'azp',
    'aud',
    'at_hash',
    'acr',
    'kid',
    'aal',
    'client_id',
    'mail',
    'firstname',
    'lastname',
    'samaccountname',
    'exp',
    'memberof',
  ])
  baseKeys.forEach((key: string) => {
    delete copy[key]
  })

  return copy
}
