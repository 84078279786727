import { AuthenticationBaseProps } from './AuthenticationProps'
import { AuthContext, LoginHandler } from '../context'

interface GenerateAPIParams
  extends Required<Pick<AuthenticationBaseProps, 'popUp'>>,
    Pick<AuthenticationBaseProps, 'SSOCookieName' | 'doesSSOCookieMatch'>,
    Pick<AuthContext, 'login' | 'logout' | 'session'> {}

const generateAPI = ({
  session,
  SSOCookieName,
  doesSSOCookieMatch,
  login,
  logout,
  popUp,
}: GenerateAPIParams): AuthContext => {
  const isAuthenticated = (): boolean =>
    (session ? session.isAuthenticated() : false) &&
    (session && SSOCookieName && doesSSOCookieMatch
      ? doesSSOCookieMatch(SSOCookieName, session)
      : true)
  const isAuthorized = (groups: string[] = []): boolean =>
    session ? session.isAuthorized(groups) : false
  const apiLogin: LoginHandler = (args) => {
    if (!isAuthenticated()) {
      login(args)
    }
  }
  const apiLogout = () => {
    if (isAuthenticated()) {
      logout()
    }
  }
  const isFullPageAuth = (): boolean => !popUp

  return {
    isAuthenticated,
    isAuthorized,
    session,
    login: apiLogin,
    logout: apiLogout,
    isFullPageAuth,
  }
}

export default generateAPI
