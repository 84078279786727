import jwt from 'jsonwebtoken'
import {
  tokensAreValid,
  fetchUserInfo,
  AccessTokenType,
  UserInfoResponse,
} from './utils/tokens'
import { formatUserInfo, UserInfo } from './utils/format'

/**
 * Thrown when trying to access data from a `Session` class that hasn't been validated yet
 * @class
 * @extends {Error}
 */
class SessionError extends Error {
  constructor() {
    super('Please validate session before trying to access session info')
    Error.captureStackTrace(this, SessionError)
  }
}

/**
 * Type of object that is used as `config` parameter in the `Session` constructor
 */
interface SessionConfig {
  /** oauth client ID to use for token validation */
  clientId: string
  /** base authorization URL for ID2 */
  authorizationUrl: string
  /** the nonce used to generate the tokens */
  nonce: string
  /** type of token passed for `accessToken` */
  accessTokenType: AccessTokenType
}

/**
 * Class used to hold a logged in user's session such as their user information and credentials
 */
class Session {
  /**
   * @readonly
   * @private
   * the logged in user's authentication token
   */
  readonly #accessToken: string
  /**
   * @readonly
   * @private
   * the logged in user's identity token
   */
  readonly #identityToken: string
  /**
   * @private
   * @readonly stored configuration for this session instance
   * */
  readonly #config: SessionConfig
  /** @private whether or not the Session has been validated yet */
  #validated: boolean
  /** @private time when the tokens are to expire */
  #expires: number = 0
  /** @private the logged in user's information */
  #userInfo: UserInfo | null = null

  /**
   * @constructor
   * @param accessToken   - the users authentication token supplied by ID2
   * @param identityToken - the users identity token supplied by ID2
   * @param config        - configuration option for the session instance
   * @throws {TypeError} when no `accessToken` or `identityToken` is given
   */
  constructor(
    accessToken: string,
    identityToken: string = '',
    config: SessionConfig
  ) {
    // check for required accessToken
    if (typeof accessToken !== 'string' || !accessToken.length) {
      throw TypeError('Access token must be provided as a string')
    }

    // check for optional identityToken
    if (
      identityToken &&
      (typeof identityToken !== 'string' || !identityToken.length)
    ) {
      throw TypeError('You provided an identity token but it is not a string')
    }

    this.#accessToken = accessToken
    this.#identityToken = identityToken
    this.#config = config
    this.#validated = false
  }

  /**
   * getter for user's authentication token
   * @public
   * @returns the user's authentication token
   */
  get accessToken(): string {
    return this.#accessToken
  }

  /**
   * getter for the logged in user's identity token
   * @public
   * @deprecated use `userInfo` instead
   * @returns the user's identity token
   */
  get identityToken(): string {
    return this.#identityToken
  }

  /**
   * getter for Sessions configuration
   * @public
   */
  get config(): SessionConfig {
    return this.#config
  }

  /**
   * whether or not the session has been validated yet
   * @public
   */
  get validated(): boolean {
    return this.#validated
  }

  /**
   * Gets the decoded value of the `identityToken`
   * @public
   * @deprecated use `userInfo` instead
   * @throws {SessionError} if session has not been validated yet
   */
  get identity() {
    if (!this.validated) {
      throw new SessionError()
    }

    return jwt.decode(this.identityToken, { json: true })
  }

  /**
   * decoded accessToken getter
   * @public
   * @throws {SessionError} if session has not been validated yet
   * @returns the decoded value of `accessToken`
   */
  get access() {
    if (!this.#validated) {
      throw new SessionError()
    }

    return jwt.decode(this.accessToken, { json: true })
  }

  /**
   * userInfo getter
   * @public
   * @throws {SessionError} if session has not been validated yet
   * @returns the logged in user's information
   */
  get userInfo(): UserInfo | null {
    if (!this.validated) {
      throw new SessionError()
    }

    return this.#userInfo
  }

  /**
   * Validates the given tokens and user information against ID2 then freezes this instance
   * @async
   * @public
   * @returns whether the validation was successful or not
   */
  async validate() {
    // if identity token was not provided, fetch a user info using accessToken
    let userInfo: UserInfoResponse | undefined
    if (!this.#identityToken) {
      const fetchResp = await fetchUserInfo(
        this.#accessToken,
        this.#config.authorizationUrl
      )
      if (!fetchResp) {
        this.#validated = false
        this.#expires = 0
        Object.freeze(this)
        return false
      } else {
        userInfo = fetchResp
      }
    }
    const isValid = await tokensAreValid(
      this.#accessToken,
      this.#identityToken,
      this.#config.authorizationUrl,
      this.#config.clientId,
      this.#config.nonce
    )
    if (isValid) {
      this.#validated = isValid
      this.#expires = jwt.decode(this.accessToken, { json: true })?.exp || 0

      const { accessTokenType } = this.#config
      this.#userInfo = formatUserInfo(this.#accessToken, this.#identityToken, {
        accessTokenType,
        userInfoOverride: userInfo,
      })
    }

    // once validated (or not) freeze object to prevent manipulation
    Object.freeze(this)
    return isValid
  }

  /**
   * basic authentication check based on current session
   * @public
   * @throws {SessionError} if session has not been validated yet
   * @returns whether the session instance has been validated and has not yet expired
   */
  isAuthenticated() {
    if (!this.validated) {
      throw new SessionError()
    }

    return this.validated && !this.hasExpired
  }

  /**
   * basic authorization check based on current session
   * @param {string[]} groups - groups to compare against the user's `memberOf`
   * @returns whether or not the logged in user has those one of the given `groups`
   */
  isAuthorized(groups: string[] = []) {
    const userInfo = this.userInfo
    const formattedGroups = groups.map((group) => group.toUpperCase())
    if (!userInfo) return false
    return (
      this.isAuthenticated() &&
      (groups.length
        ? [...userInfo.memberOf]
            .map((ldap) => ldap.toUpperCase())
            .filter((group) => formattedGroups.includes(group)).length > 0
        : true)
    )
  }

  /**
   * expiry check for session
   * @throws {SessionError} if session has not been validated yet
   * @returns whether or not the current tokens have expired
   */
  get hasExpired() {
    if (!this.validated) {
      throw new SessionError()
    }

    // Convert date from milliseconds to seconds
    const now = Date.now() / 1000
    // Is expire time in the past?
    return this.#expires < now
  }
}

export default Session
