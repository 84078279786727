import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react'
import _ from 'lodash'
import CanvasDraw from 'react-canvas-draw'
import { makeStyles, Typography } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import TextFieldsIcon from '@mui/icons-material/Title'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import UndoIcon from '@mui/icons-material/Undo'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material'

const useStyles = makeStyles(() => ({
  canvas: {
    height: '350px !important',
    width: '600px !important',
  },
  hidden: { display: 'none' },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  actions: {
    height: '31px',
    width: '600px',
    background: '#EDEDED',
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 'auto',
    width: '200px',
  },
  icon: {
    width: '20px',
    height: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selected: {
    background: 'rgba(217, 217, 217, 1)',
    height: '31px',
  },
  colorPicker: {
    width: '24px',
    height: '24px',
    border: 'none',
  },
  doneButton: {
    background: 'rgb(204, 0, 0)',
    color: '#fff',
    border: 'none',
    width: '50px',
    height: '20px',
    borderRadius: '2px',
  },
  textInput: {
    marginRight: '10px',
  },
  primaryButton: {
    background: 'rgba(62, 114, 219, 1) !important',
    color: '#FFFFFF !important',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  secondaryButton: {
    textTransform: 'none !important',
  },
  title: { mixBlendMode: 'normal', opacity: 0.87 },
}))

const DraggableElement = ({
  getPosition = () => {},
  specifications = {},
  handleEdit = () => {},
}) => {
  const [dragging, setDragging] = useState(false)
  const [position, setPosition] = useState({
    x: specifications.x,
    y: specifications.y,
  })
  const [offset, setOffset] = useState({ x: 0, y: 0 })

  const handleMouseDown = (e) => {
    e.preventDefault()
    setDragging(true)
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    })
  }

  const handleMouseMove = (e) => {
    if (!dragging) return
    const x = e.clientX - offset.x
    const y = e.clientY - offset.y
    setPosition({
      x,
      y,
    })
  }

  const handleMouseUp = () => {
    setDragging(false)
    getPosition(position.x, position.y)
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        color: specifications.color,
        fontSize: '16px',
        fontFamily: 'helvetica',
        '&:hover': {
          cursor: 'move',
          border: '1px dashed black',
          borderRadius: '2px',
          padding: '10px',
          left: `${position.x - 11}px`,
          top: `${position.y - 11}px`,
          '& .MuiSvgIcon-root': {
            display: 'block',
          },
        },
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onDoubleClick={handleEdit}
    >
      {specifications.text}
      <EditIcon
        sx={{
          position: 'absolute',
          top: '-9px',
          right: '-9px',
          cursor: 'pointer',
          display: 'none',
        }}
        onClick={handleEdit}
        className="text-edit"
      />
    </Box>
  )
}

const CanvasDrawing = forwardRef(
  (
    {
      fileUrl,
      getAnnotatedFile,
      downloadImage,
      downloadFileNameAndExtension,
      brushStrokes,
      savedTextPositions,
      savedDragPositions,
    },
    ref
  ) => {
    const canvasRef = useRef(null)
    const [showInput, setShowInput] = useState({ show: false, x: 0, y: 0 })
    const [enableText, setEnableText] = useState(false)
    const [text, setText] = useState('')
    const [color, setColor] = useState('#000000')
    const [loadImage, setLoadImage] = useState(true)
    const [textPositions, setTextPositions] = useState([])
    const [dragPositions, setDragPositions] = useState([])
    const [imageAspectType, setImageAspectType] = useState('')
    const [fileLocalUrl, setFileLocalUrl] = useState('')

    const styles = useStyles()

    const inputRef = useRef(null)
    const inputEditRef = useRef(null)

    const canvasWrapperRef = useRef(null)

    const getImageAspectRatio = (imageUrl) => {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => {
          const aspectRatio = image.width / image.height
          let description
          if (aspectRatio === 1) {
            description = 'Square'
          } else if (aspectRatio < 1) {
            description = 'Vertical'
          } else {
            description = 'Horizontal'
          }
          const result = {
            aspectRatio: aspectRatio,
            description: description,
          }
          resolve(result)
        }
        image.onerror = () => {
          reject(new Error('Failed to load the image.'))
        }
        // Start loading the image
        image.src = imageUrl
      })
    }

    useEffect(() => {
      setTextPositions(() => savedTextPositions)
      setDragPositions(() => savedDragPositions)
    }, [savedTextPositions, savedDragPositions])

    useEffect(() => {
      if (brushStrokes) {
        canvasRef.current.loadSaveData(brushStrokes)
      }
    }, [brushStrokes])

    useEffect(() => {
      getImageAspectRatio(fileUrl)
        .then((result) => {
          setImageAspectType(result.description)
        })
        .catch((error) => {
          setImageAspectType('')
        })
      fetch(fileUrl, { method: 'get' }).then(async (res) => {
        const blob = await res.blob()
        setFileLocalUrl(() => URL.createObjectURL(blob))
      })
    }, [fileUrl])

    const handleClick = useCallback(
      (x, y) => {
        if (enableText) {
          setShowInput({
            show: true,
            x,
            y: y + 70,
          })
          if (inputRef && inputRef.current) {
            inputRef.current.focus()
          }
        }
      },
      [enableText]
    )

    const handleSave = (e) => {
      e.stopPropagation()
      setShowInput(false)
      const x = showInput.x
      const y = showInput.y - 70
      setTextPositions((prev) => [
        ...prev,
        {
          text,
          color,
          x,
          y,
          showInput: false,
        },
      ])
      setDragPositions((prev) => [...prev, { x, y }])
      setText('')
    }

    const combineDrawing = async () => {
      const isTextAvailable = textPositions?.filter((text) => text.text !== '')
        ?.length
      const drawingsCordinate = canvasRef.current.getSaveData()
      if (
        isTextAvailable === 0 &&
        JSON.parse(drawingsCordinate)?.lines?.length === 0
      ) {
        const res = await fetch(fileUrl, { method: 'get' })
        const blob = await res.blob()
        return { blob: blob, dataUri: fileUrl }
      } else {
        const background = canvasRef.current.canvasContainer.children[0]
        const drawing = canvasRef.current.canvasContainer.children[1]
        const canvas = document.createElement('canvas')
        canvas.width = background.width
        canvas.height = background.height

        // composite now
        canvas.getContext('2d').drawImage(background, 0, 0)
        canvas.getContext('2d').globalCompositeOperation = 'source-over'
        canvas.getContext('2d').globalAlpha = 1.0
        canvas.getContext('2d').drawImage(drawing, 0, 0)

        let dataUri = canvas.toDataURL('image/png', 1.0)

        const data = dataUri.split(',')[1]
        const mimeType = dataUri.split(';')[0].slice(5)

        const bytes = window.atob(data)
        const buf = new ArrayBuffer(bytes.length)
        const arr = new Uint8Array(buf)

        for (let i = 0; i < bytes.length; i++) {
          arr[i] = bytes.charCodeAt(i)
        }

        const blob = new Blob([arr], { type: mimeType })
        return { blob: blob, dataUri: dataUri }
      }
    }

    const saveImage = (blob, filename) => {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.click()
      window.URL.revokeObjectURL(url)
    }

    const drawTextOnCanvas = () => {
      const canvas = canvasRef.current.canvasContainer.children[0]
      const ctx = canvas.getContext('2d')
      ctx.font = '16px Helvetica'
      return new Promise((res) => {
        if (!textPositions.length) {
          res()
          return false
        }
        for (let i = 0; i < textPositions.length; i++) {
          const pos = textPositions[i]
          const dragPos = dragPositions[i]
          ctx.fillStyle = pos.color
          ctx.fillText(pos.text, dragPos.x, dragPos.y)
          if (i === textPositions.length - 1) {
            res()
          }
        }
      })
    }

    useImperativeHandle(ref, () => ({
      async handleExport() {
        await drawTextOnCanvas()
        const { blob } = await combineDrawing()
        const drawingsCordinate = canvasRef.current.getSaveData()
        getAnnotatedFile(
          blob,
          textPositions,
          dragPositions,
          drawingsCordinate,
          fileUrl
        )
        if (downloadImage) {
          saveImage(blob, downloadFileNameAndExtension)
        }
      },
    }))

    const drawImageScaled = useCallback((img, canvas, ctx) => {
      var hRatio = canvas.width / img.width
      var vRatio = canvas.height / img.height
      var ratio = Math.min(hRatio, vRatio)
      var centerShift_x = (canvas.width - img.width * ratio) / 2
      var centerShift_y = (canvas.height - img.height * ratio) / 2
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        centerShift_x,
        centerShift_y,
        img.width * ratio,
        img.height * ratio
      )
    }, [])

    useEffect(() => {
      if (fileLocalUrl && imageAspectType) {
        const image = new Image()
        image.src = fileLocalUrl
        image.crossOrigin = 'anonymous'
        const canvas = canvasRef.current.canvasContainer.children[0]
        image.addEventListener('load', (e) => {
          const ctx = canvas.getContext('2d')
          drawImageScaled(image, canvas, ctx, imageAspectType)
        })
      }
    }, [loadImage, drawImageScaled, fileLocalUrl, imageAspectType])

    useEffect(() => {
      canvasRef.current.canvasContainer.addEventListener('click', function (
        event
      ) {
        const boundingRectangle = canvasRef.current.canvasContainer.children[0].getBoundingClientRect()
        let x = event.clientX - boundingRectangle.x
        let y = event.clientY - boundingRectangle.y
        handleClick(x, y)
      })
    }, [handleClick])

    const clearCanvas = () => {
      //   const canvas1 = canvasRef.current.canvasContainer.children[0]
      //   const context1 = canvas1.getContext('2d')
      //   context1.clearRect(0, 0, canvas1.width, canvas1.height)
      //   const canvas2 = canvasRef.current.canvasContainer.children[1]
      //   const context2 = canvas2.getContext('2d')
      //   context2.clearRect(0, 0, canvas2.width, canvas2.height)
      canvasRef.current.eraseAll()
      setDragPositions([])
      setTextPositions([])
      setLoadImage((bool) => !bool)
    }

    const handleColorChange = (e) => {
      e.stopPropagation()
      if (e.target.value) setColor(e.target.value)
    }

    const undoCanvas = (e) => {
      e.stopPropagation()
      canvasRef.current.undo()
    }

    const handleDrag = (x, y, index) => {
      let positions = [...dragPositions]
      positions.splice(index, 1, { x, y: y + 15 })
      setDragPositions(() => [...positions])
    }

    const handleEditText = (index) => {
      const position = textPositions[index]
      const newPos = { ...position, showInput: true }
      setTextPositions((prev) => {
        prev.splice(index, 1, newPos)
        return [...prev]
      })
      setColor(textPositions.color)
      setText(position.text)
      if (inputEditRef && inputEditRef.current) {
        inputEditRef.current.focus()
      }
    }

    const handleEditTextChange = _.debounce((value, index) => {
      const position = textPositions[index]
      const newPos = { ...position, text: value }
      setTextPositions((prev) => {
        prev.splice(index, 1, newPos)
        return [...prev]
      })
    }, 100)

    const handleEditSave = (e, index) => {
      e.stopPropagation()
      const position = textPositions[index]
      const dragPos = dragPositions[index]
      const newPos = { ...position, ...dragPos, showInput: false }
      setText('')
      setTextPositions((prev) => {
        prev.splice(index, 1, newPos)
        return [...prev]
      })
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.actions}>
          <div className={styles.icons}>
            <CreateIcon
              className={[styles.icon, enableText ? '' : styles.selected].join(
                ' '
              )}
              onClick={() => {
                setEnableText(false)
                setShowInput(() => ({ show: false, x: 0, y: 0 }))
              }}
            />
            <TextFieldsIcon
              className={[styles.icon, !enableText ? '' : styles.selected].join(
                ' '
              )}
              onClick={() => {
                setEnableText(true)
                setShowInput(() => ({ show: false, x: 0, y: 0 }))
              }}
            />
            <input
              type="color"
              onChange={handleColorChange}
              className={styles.colorPicker}
              value={color}
            />
            <DeleteForeverIcon className={styles.icon} onClick={clearCanvas} />
            <UndoIcon className={styles.icon} onClick={undoCanvas} />
          </div>
        </div>

        <div style={{ position: 'relative' }} ref={canvasWrapperRef}>
          <CanvasDraw
            lazyRadius={0}
            brushRadius={2}
            ref={canvasRef}
            disabled={enableText}
            className={styles.canvas}
            brushColor={color}
            gridColor={'#000000'}
            hideGrid={true}
            hideInterface={true}
          />
          {!!textPositions.length &&
            textPositions.map((pos, i) => {
              const dragPos = { ...pos, ...dragPositions[i] }

              if (pos.showInput) {
                return (
                  <div
                    style={{
                      position: 'absolute',
                      left: dragPos.x,
                      top: dragPos.y,
                      border: '1px solid black',
                      padding: '5px',
                    }}
                  >
                    <input
                      type="text"
                      defaultValue={pos.text}
                      className={styles.textInput}
                      onChange={(e) => handleEditTextChange(e.target.value, i)}
                      ref={inputEditRef}
                    />
                    <button
                      className={styles.doneButton}
                      onClick={(e) => handleEditSave(e, i)}
                    >
                      Save
                    </button>
                  </div>
                )
              }
              return (
                <DraggableElement
                  key={i}
                  getPosition={(x, y) => handleDrag(x, y, i)}
                  specifications={dragPos}
                  handleEdit={() => handleEditText(i)}
                />
              )
            })}
        </div>

        {showInput.show && enableText && !inputEditRef.current && (
          <div
            style={{
              position: 'absolute',
              left: showInput.x,
              top: showInput.y,
              border: '1px solid black',
              padding: '5px',
            }}
          >
            <input
              type="text"
              value={text}
              className={styles.textInput}
              onChange={(e) => setText(e.target.value)}
              ref={inputRef}
            />
            <button className={styles.doneButton} onClick={handleSave}>
              Save
            </button>
          </div>
        )}
      </div>
    )
  }
)

const AnnotationDialog = ({
  open,
  handleClose,
  fileUrl,
  getAnnotatedFile = () => {},
  downloadImage,
  downloadFileNameAndExtension,
  cancelText,
  saveText,
  loader = false,
  title = 'Annotate on file',
  brushStrokes = '',
  savedTextPositions = [],
  savedDragPositions = [],
}) => {
  const ref = useRef()
  const styles = useStyles()
  return (
    <Dialog open={open} onClose={handleClose}>
      {!!loader && <LinearProgress />}
      <DialogTitle>
        <Typography className={styles.title}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <CanvasDrawing
          fileUrl={fileUrl}
          downloadImage={downloadImage}
          getAnnotatedFile={getAnnotatedFile}
          ref={ref}
          downloadFileNameAndExtension={downloadFileNameAndExtension}
          brushStrokes={brushStrokes}
          savedDragPositions={savedDragPositions}
          savedTextPositions={savedTextPositions}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="secondary"
          className={styles.secondaryButton}
          onClick={handleClose}
        >
          {cancelText}
        </Button>
        <Button
          type="primary"
          className={styles.primaryButton}
          onClick={() => ref.current.handleExport()}
        >
          {saveText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AnnotationDialog
