import { useCallback } from 'react'

import { clearAllStorage } from '../utils/storage'
import {
  createLogoutUrl,
  AuthLogoutParams,
  createPopupWindow,
  PopUpOptions,
} from '../utils/urls'

import { AuthenticationBaseProps } from './AuthenticationProps'
import { UseAuthState } from './useAuthState'

interface UseLogoutParams
  extends Required<
      Pick<AuthenticationBaseProps, 'accessTokenKey' | 'logoutUrl' | 'popUp'>
    >,
    Pick<AuthenticationBaseProps, 'logoutRedirect'>,
    Pick<UseAuthState, 'logoutAction'> {
  popUpOptions?: PopUpOptions
}

const useLogout = ({
  accessTokenKey,
  logoutRedirect,
  logoutUrl,
  popUp,
  popUpOptions,
  logoutAction,
}: UseLogoutParams) => {
  return useCallback(() => {
    // don't allow logout until token checks have finished
    clearAllStorage(accessTokenKey)
    const logoutParams: AuthLogoutParams | undefined = logoutRedirect
      ? {
          logoutRedirect,
        }
      : undefined
    // redirect to OAuth logout page
    const formattedLogoutUrl = createLogoutUrl(
      new URL(logoutUrl).toString(),
      logoutParams
    )
    if (formattedLogoutUrl) {
      if (!popUp) {
        window.location.replace(formattedLogoutUrl)
      } else {
        createPopupWindow(formattedLogoutUrl, popUpOptions)
        logoutAction()
      }
    }
  }, [
    accessTokenKey,
    logoutAction,
    logoutRedirect,
    logoutUrl,
    popUp,
    popUpOptions,
  ])
}

export default useLogout
