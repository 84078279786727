import {checkIsArray, checkStringIsNonEmpty, getCreationFailedMessage} from '../validate'
import constants from './constants'
import Event from './Event'

/**
 * Custom user defined event
 * @memberOf module:harbinger/models
 */
class CustomEvent extends Event {
  /**
   * Constructs the event with the given custom datapoints and custom event id.
   * If the construction of the object is unsuccessful, it returns an empty object.
   * @param customEventId {string}
   * @param customDataPoints {CustomDataPoint}
   * @returns {ViewEvent|{}}
   */
  constructor (customEventId, customDataPoints = []) {
    super({ eventType: constants.EVENT_TYPES.CUSTOM })

    this.validate(customEventId, 'customEventId', checkStringIsNonEmpty)
      .and(customDataPoints, 'customDataPoints', checkIsArray)
      .done(getCreationFailedMessage('CustomEvent'))

    this.customEventId = customEventId
    this.customDataPoints = customDataPoints

    Object.freeze(this)
  }
}

export default CustomEvent
