import { useCallback } from 'react'
import { clearAllStorage } from '../../utils/storage'
import { AuthLoginParams, createLoginUrl } from '../../utils/urls'
import { LoginHandler, RedirectParam } from '../../context'
import { fullPageLogin, popUpLogin, PopUpLoginParams } from './login'

export interface UseLoginParams<Url extends string = string>
  extends Omit<PopUpLoginParams, 'loginRedirect' | 'loginUrl' | 'redirect'> {
  accessTokenKey: string
  authorizationUrl: Url
  popUp: boolean
  loginParams: AuthLoginParams
}

const useLogin = ({
  accessTokenKey,
  authorizationUrl,
  onLogin,
  popUp,
  popUpOptions,
  saveSession,
  loginParams,
}: UseLoginParams) => {
  const login: LoginHandler = (param?) => {
    const redirect = (param as RedirectParam)?.redirect || ''
    clearAllStorage(accessTokenKey)
    const loginUrl = createLoginUrl(authorizationUrl, loginParams)
    if (loginUrl) {
      if (!popUp) {
        fullPageLogin({ redirect, loginUrl })
      } else {
        popUpLogin({
          redirect,
          loginUrl,
          popUpOptions,
          onLogin,
          saveSession,
          loginRedirect: loginParams.loginRedirect,
        })
      }
    }
  }
  return useCallback(login, [
    accessTokenKey,
    authorizationUrl,
    loginParams,
    onLogin,
    popUp,
    popUpOptions,
    saveSession,
  ])
}

export default useLogin
