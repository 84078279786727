import { useEffect, memo } from 'react'
import axios from 'axios'

import { injectBearer } from '../utils/format'
import Session from '../Session'
import { ShouldSendHeaders } from '../utils/urls'
import { AccessTokenType } from '../utils/tokens'

interface AuthInterceptorProps {
  session: Session
  shouldSendHeaders: ShouldSendHeaders
  tokenType: AccessTokenType
}

const AuthInterceptor = memo(
  ({ session, shouldSendHeaders, tokenType }: AuthInterceptorProps) => {
    useEffect(() => {
      const interceptor = axios.interceptors.request.use(
        (config) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              config.headers.common,
              'Authorization'
            )
          ) {
            if (
              session.accessToken &&
              config.url &&
              shouldSendHeaders(config.url) &&
              tokenType === 'Bearer'
            ) {
              config.headers.common.Authorization = injectBearer(
                session.accessToken
              )
            }
          } else if (!config.headers.Authorization) {
            delete config.headers.Authorization
          }
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      // eject axios interceptor when un-mounting
      return () => {
        axios.interceptors.request.eject(interceptor)
      }
    })

    return null
  }
)

export default AuthInterceptor
