import React from 'react'
import { Grid, Typography, Tooltip, IconButton } from '@mui/material'
import { Info } from '@material-ui/icons'
const TcinStatus = ({
  item,
  values,
  bgColor,
  textSummary,
  isToolTipSummary,
}) => {
  return (
    <Grid
      sx={{
        ml: 1,
        height: '112px',
        width: '211px',
        padding: '8px 12px 0 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        background: bgColor,
      }}
    >
      <Grid container justifyContent="space-between">
        <Typography
          sx={{ fontWeight: '700', fontSize: '14px', marginTop: '8px' }}
        >
          {item}
        </Typography>
        {isToolTipSummary && (
          <Tooltip title={textSummary}>
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Typography sx={{ fontWeight: '700', fontSize: '24px' }}>
        {values}
      </Typography>
    </Grid>
  )
}

export default TcinStatus
