import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Grid,
  IconButton,
  LinearProgress,
  Box,
  Typography,
} from '@material-ui/core'
import { DropzoneAreaBase } from 'material-ui-dropzone'
import { map, forEach, isEmpty } from 'lodash'

import TaskActionButton from '../TaskActionButton/TaskActionButton'
import {
  Clear,
  Cancel,
  CheckCircle,
  Cached,
  AccessTime,
} from '@material-ui/icons'

const DialogContainer = styled.div``

const UploadFiles = ({
  tcin = '',
  uploadFileHandler = () => {},
  fileStatesList = {},
  removeFiles = () => {},
  maxFileSize = 1000000 * 2048, // 2 GB limit
  onSubmit = () => {},
  onCancelUpload = () => {},
  submitLoading = false,
  dropzoneArray = [
    {
      text: 'Drag and drop or browse',
      fileType: 'DEFAULT',
      acceptedFileTypes: ['.zip'],
    },
  ],
  hideDialogActions = false,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [uploadButtonType, setUploadButtonType] = useState('upload')
  const [sendForReviewDisabled, setSendForReviewDisabled] = useState(true)
  const [progressObj, setProgressObj] = useState({
    successCount: 0,
    errorCount: 0,
    totalFiles: 0,
  })

  let dropzoneGridVal = 12
  if (dropzoneArray.length > 2) {
    dropzoneGridVal = 6
  }

  const handleOpen = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setDialogOpen(true)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setDialogOpen(false)
  }

  const handleCloseAndCancel = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setDialogOpen(false)
    onCancelUpload()
  }

  const addFiles = (newFiles, fileType) => {
    newFiles.forEach((file) => uploadFileHandler(file, fileType))
  }

  useEffect(() => {
    let successCount = 0
    let errorCount = 0
    const fileList = map(fileStatesList, (fileStates) => {
      if (fileStates.status === 'success') {
        successCount++
      }
      if (fileStates.status === 'error') {
        errorCount++
      }
      return ''
    })
    if (errorCount > 0) {
      setUploadButtonType('error')
    } else if (successCount < fileList.length) {
      setUploadButtonType('uploading')
    } else if (successCount > 0 && successCount === fileList.length) {
      setUploadButtonType('success')
    } else {
      setUploadButtonType('upload')
    }
    setSendForReviewDisabled(
      successCount !== fileList.length || fileList.length === 0
    )

    setProgressObj({
      successCount,
      errorCount,
      totalFiles: fileList.length,
    })
  }, [fileStatesList])

  let color = 'blue'

  const { totalFiles, errorCount } = progressObj
  let progress = 0

  if (totalFiles > 0 && !isEmpty(fileStatesList)) {
    forEach(fileStatesList, (file) => {
      progress += file.progress
    })
    progress = progress / totalFiles
  }
  if (errorCount > 0) {
    color = '#CC0000'
  } else if (progress === 100) {
    color = 'green'
  }

  return (
    <div
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
      }}
    >
      <TaskActionButton
        buttonType={uploadButtonType}
        onClick={handleOpen}
        color={color}
        progress={progress}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        maxWidth="md"
        fullWidth
      >
        {submitLoading ? <LinearProgress /> : <div style={{ height: 4 }} />}
        <DialogContainer>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Upload Files for {tcin}</div>
              <Button color="primary" onClick={handleClose}>
                Hide Panel
              </Button>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  {dropzoneArray.map((o, i) => (
                    <Grid key={i} item xs={dropzoneGridVal}>
                      <DropzoneAreaBase
                        maxFileSize={maxFileSize}
                        acceptedFiles={o.acceptedFileTypes}
                        showAlerts={['error']}
                        filesLimit={10}
                        onAdd={(newFiles) => addFiles(newFiles, o.fileType)}
                        dropzoneText={o.text}
                        showPreviews={false}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <FilesList removeFiles={removeFiles} files={fileStatesList} />
              </Grid>
            </Grid>
          </DialogContent>
          {hideDialogActions ? null : (
            <DialogActions>
              <Button onClick={handleCloseAndCancel} color="primary">
                Cancel Upload
              </Button>
              <Button
                autoFocus
                onClick={onSubmit}
                variant="contained"
                color="primary"
                disabled={sendForReviewDisabled || submitLoading}
              >
                Send For Review
              </Button>
            </DialogActions>
          )}
        </DialogContainer>
      </Dialog>
    </div>
  )
}

UploadFiles.propTypes = {
  fileStatesList: PropTypes.object,
  maxFileSize: PropTypes.number,
  removeFiles: PropTypes.func,
  tcin: PropTypes.string,
  uploadFileHandler: PropTypes.func,
}

const FilesList = ({ files = [], removeFiles = () => {} }) => {
  return (
    <div>
      <List>
        {map(files, (file, key) => (
          <ListItem key={key}>
            <ListItemAvatar>
              <IconButton onClick={() => removeFiles(key)}>
                <Clear />
              </IconButton>
            </ListItemAvatar>
            <ListItemText
              primary={
                <div>
                  {file.name} {file.fileType && ` (${file.fileType})`}
                  <LinearProgressWithLabel
                    value={file.progress}
                    valueBuffer={file.progress}
                  />
                </div>
              }
            />
            <ListItemSecondaryAction>
              <div>
                {file.status === 'success' && (
                  <CheckCircle style={{ color: 'green' }} />
                )}
                {file.status === 'error' && <Cancel style={{ color: 'red' }} />}
                {file.status === 'in_progress' && (
                  <Cached style={{ color: 'blue' }} />
                )}
                {file.status === 'waiting' && (
                  <AccessTime style={{ color: 'grey' }} />
                )}
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="buffer" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

FilesList.propTypes = {
  files: PropTypes.object,
  removeFiles: PropTypes.func,
}

export default UploadFiles
