/* eslint-disable no-unused-vars */
import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import TaskActionButton from '../TaskActionButton/TaskActionButton'
import TaskStatusLabel from '../TaskStatusLabel/TaskStatusLabel'
import DisplayThumbnail from '../DIsplayThumbnail/DisplayThumbnail'
import TaskSummary from '../TaskSummary/TaskSummary'
import { ArrowUpward, ArrowDownward, Remove } from '@material-ui/icons'

function EnhancedTableHead(props) {
  const { classes, order, orderBy, tableheaders, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {tableheaders.map((headCell) => (
          <React.Fragment>
            {headCell.sortable ? (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={true}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.label}
              </TableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  sortHandler: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  container: {
    // maxHeight: 440,
    height: '75vh',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tablerow: {
    cursor: 'pointer',
  },
  speechBubble: {
    position: 'relative',
    background: '#666666',
    color: 'white',
    borderRadius: '.3em',
    padding: '5px 10px',
    fontWeight: 600,
    '&::after': {
      content: '" "  !important',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      width: 0,
      height: 0,
      border: '8px solid transparent',
      borderTopColor: '#666666',
      borderBottom: 0,
      borderLeft: 0,
      marginLeft: -5,
      marginBottom: -8,
    },
  },
}))

const rows = []

const getPriorityIcon = (priority) => {
  if (priority === 'high') return <ArrowUpward style={{ color: 'red' }} />
  if (priority === 'low') return <ArrowDownward style={{ color: 'green' }} />
  return <Remove style={{ color: 'gray' }} />
}

export default function EnhancedTable({
  tablebody,
  tableType,
  totalTasks,
  tableheaders,
  loading,
  error,
  hasMore,
  setPageNum,
  downloadFile,
  onRowClick,
  setOpenDrawer,
  order,
  orderBy,
  sortHandler,
}) {
  const classes = useStyles()
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const observer = useRef()
  const lastTaskElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          //let maxPageNum = Math.ceil(totalTasks / 30)
          setPageNum((prevPageNumber) => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, row) => {
    onRowClick(event, true, row.tcin, row.taskId, row.rawData)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const HandleThumbnail = (props) => {
    return <DisplayThumbnail url={props.thumbnailUrl} />
  }

  const StartActionButton = (props) => {
    return <TaskActionButton onClick={() => {}} buttonType="start_task" />
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tablebody.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              //onSelectAllClick={handleSelectAllClick}
              onRequestSort={sortHandler}
              rowCount={tablebody.length}
              tableheaders={tableheaders}
            />
            <TableBody>
              {tablebody.map((row, index) => {
                //const isItemSelected = isSelected(row.tcin)
                const labelId = `enhanced-table-checkbox-${index}`
                if (tableType === 'vendor') {
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      className={classes.tablerow}
                      // role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.tcin}
                      //selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        id={labelId}
                        align="left"
                      >
                        <TaskStatusLabel taskStatus={row.status} />
                      </TableCell>
                      <TableCell align="left">{row.tcin}</TableCell>
                      <TableCell align="left">{row.prodDesc}</TableCell>
                      <TableCell align="left">{row.brand}</TableCell>
                      <TableCell align="left">{row.vendorName}</TableCell>
                      <TableCell align="left">{row.lastUpdatedAt}</TableCell>
                      <TableCell align="left">{row.dueDate}</TableCell>
                      <TableCell align="left">{row.launchDate}</TableCell>
                    </TableRow>
                  )
                } else {
                  if (tablebody.length === index + 1) {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        //role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.tcin}
                        className={classes.tablerow}
                        //selected={isItemSelected}
                        ref={lastTaskElementRef}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                        >
                          {row.tcin}
                          {/* <HandleThumbnail thumbnailUrl={row.thumbnailUrl} /> */}
                        </TableCell>
                        <TableCell align="left">{row.project}</TableCell>
                        <TableCell align="left">{row.fileUrl}</TableCell>
                        <TableCell align="left">
                          <TaskStatusLabel taskStatus={row.taskstatus} />
                        </TableCell>
                        {row.taskstatus === 'APPROVED' ? (
                          <TableCell align="left">{row.approveddate}</TableCell>
                        ) : (
                          <TableCell align="left">{row.assigneddate}</TableCell>
                        )}
                        {row.taskstatus === 'APPROVED' ? (
                          <TableCell align="left">{row.recount}</TableCell>
                        ) : null}
                        {row.taskstatus === 'APPROVED' ? null : (
                          <TableCell align="left">
                            {getPriorityIcon(row.priority)}
                          </TableCell>
                        )}
                        {row.taskstatus === 'APPROVED' ? null : (
                          <TableCell align="left">{row.duedate}</TableCell>
                        )}
                        {row.taskstatus === 'APPROVED' ? null : (
                          <TableCell align="left">{row.actions}</TableCell>
                        )}
                        <TableCell align="left">{row.itemtype}</TableCell>
                        <TableCell align="left">
                          {parseInt(row.comments) ? (
                            <span className={classes.speechBubble}>
                              {row.comments}
                            </span>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    )
                  } else {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        className={classes.tablerow}
                        // role="checkbox"
                        //aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.tcin}
                        //selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                        >
                          {row.tcin}
                        </TableCell>
                        <TableCell align="left">{row.project}</TableCell>
                        <TableCell align="left">{row.fileUrl}</TableCell>
                        <TableCell align="left">
                          <TaskStatusLabel taskStatus={row.taskstatus} />
                        </TableCell>
                        {row.taskstatus === 'APPROVED' ? (
                          <TableCell align="left">{row.approveddate}</TableCell>
                        ) : (
                          <TableCell align="left">{row.assigneddate}</TableCell>
                        )}
                        {row.taskstatus === 'APPROVED' ? (
                          <TableCell align="left">{row.recount}</TableCell>
                        ) : null}
                        {row.taskstatus === 'APPROVED' ? null : (
                          <TableCell align="left">
                            {getPriorityIcon(row.priority)}
                          </TableCell>
                        )}
                        {row.taskstatus === 'APPROVED' ? null : (
                          <TableCell align="left">{row.duedate}</TableCell>
                        )}
                        {row.taskstatus === 'APPROVED' ? null : (
                          <TableCell align="left">{row.actions}</TableCell>
                        )}
                        <TableCell align="left">{row.itemtype}</TableCell>
                        <TableCell align="left">
                          {parseInt(row.comments) ? (
                            <span className={classes.speechBubble}>
                              {row.comments}
                            </span>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    )
                  }
                }
              })}
              {loading && (
                <TableRow>
                  <TableCell colSpan={4} />
                  <br />
                  <CircularProgress />
                </TableRow>
              )}
              {error && (
                <TableRow>
                  <TableCell colSpan={9} />
                  'Error'
                </TableRow>
              )}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
          <TaskSummary />
        </TableContainer>
      </Paper>
    </div>
  )
}
