// General
function label (name) {
  return name ? `'${name}'` : 'value'
}

function logWarning (warning) {
// eslint-disable-next-line no-console
  console.warn(warning)
}

export function getCreationFailedMessage (constructorName) {
  return `${constructorName || 'Instance'} not created. Invalid arguments.`
}

// Set
export function isInSet (value, set) {
  set = new Set(set)

  return set.has(value)
}

function setWarning (value, name, set) {
  return `Expected ${label(name)} to be one of ${[...set].map(item => `"${item}"`).join(', ')}. Received "${value}".`
}

export function checkIsInSet (set) {
  return (value, name) => {
    const valueIsInSet = isInSet(value, set)

    if (!valueIsInSet) logWarning(setWarning(value, name, set))

    return valueIsInSet
  }
}

// String
export function isString (value) { return typeof value === 'string' }

function stringWarning (value, name) {
  return `Expected ${label(name)} of type 'string'. Received '${typeof value}'.`
}

export function checkIsString (value, name) {
  const valueIsString = isString(value)

  if (!valueIsString) logWarning(stringWarning(value, name))

  return valueIsString
}

// Number
export function isNumber (value) { return typeof value === 'number' }

function numberWarning (value, name) {
  return `Expected ${label(name)} of type 'number'. Received '${typeof value}'.`
}

export function checkIsNumber (value, name) {
  const valueIsNumber = isNumber(value)

  if (!valueIsNumber) logWarning(numberWarning(value, name))

  return valueIsNumber
}

// Empty
export function notEmpty (value) { return value.length > 0 }

function emptyWarning (value, name) {
  return `Expected ${label(name)} to not be empty.`
}

export function checkForNonEmpty (value, name) {
  const valueIsNotEmpty = notEmpty(value)

  if (!valueIsNotEmpty) logWarning(emptyWarning(value, name))

  return valueIsNotEmpty
}

// Array
export function isArray (value) { return Array.isArray(value) }

function arrayWarning (value, name) {
  return `Expected ${label(name)} to be an Array. Received '${typeof value}'.`
}

export function checkIsArray (value, name) {
  const valueIsArray = isArray(value)

  if (!valueIsArray) logWarning(arrayWarning(value, name))

  return valueIsArray
}

// Composed assertions
export function checkStringIsNonEmpty (value, name) {
  return checkIsString(value, name) && checkForNonEmpty(value, name)
}

export function validate (value, name, assertion, werePreviousErrors) {
  werePreviousErrors = !!werePreviousErrors
  werePreviousErrors = !assertion(value, name) || werePreviousErrors
  return {
    and: (value, name, assertion) => {
      return validate(value, name, assertion, werePreviousErrors)
    },
    done: (errorMessage) => {
      if (werePreviousErrors) {
        throw new Error(errorMessage)
      }
    },
  }
}
