import DeviceMetrics from '../models/DeviceMetrics'

/**
 * Takes in either the event or general configuration object and converts its properties to snake case for the API
 * @param config - Either the event or general configuration object that needs its properties in snake case for the API
 * @returns {*} - Object with all properties in snake case
 * @ignore
 */
function mapToSnakeCase (config) {
  return Object.keys(config).reduce((result, key) => {
    result[key.replace(/(?!^)([A-Z])/g, '_$1').toLowerCase()] = config[key]

    return result
  }, {})
}

/**
 * Wrapper for the event and general configuration required to send an event to the Harbinger API
 * Ensures both have snake case properties required by the API.
 * @memberof module:harbinger/eventProcessor
 */
class EventMessage {
  constructor (event, configuration) {
    /**
     * Event configuration
     * @type {Event}
     */
    this.event = mapToSnakeCase(event)
    /**
     * General configuration
     * @type {*}
     */
    this.config = mapToSnakeCase(configuration)
    /**
     * Current device metrics during tShe event
     * @type {DeviceMetrics}
     */
    this.deviceMetrics = mapToSnakeCase(new DeviceMetrics())
  }
}
export default EventMessage
