import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#fff',
    width: '26px',
    height: '26px',
    fontSize: '14px',
    color: '#333',
    marginLeft: '4px',
    '& img': {
      width: '100%',
    },
  },
  cardAvatar: {
    backgroundColor: theme.palette.primary.avatarRed,
    '& img': {
      width: '100%',
    },
  },
}))

function CustomAvatar(props) {
  const [loaded, setLoaded] = useState(false)
  const classes = useStyles()
  const { data, isCard, customClasses } = props
  let displayName, loginId
  if (data.display_name) {
    displayName = data.display_name
    loginId = data.login_id
  } else {
    displayName = data.displayName
    loginId = data.loginId
  }
  const imageUrl =
    'http://itgtsites.target.com/User%20Photos/Profile%20Pictures/DHC_' +
    loginId +
    '_MThumb.jpg'
  let arr
  if (displayName.includes('.')) {
    arr = (displayName || '').split('.')
  } else {
    arr = (displayName || '').split(' ')
  }
  return (
    <Avatar
      aria-label="Profile"
      className={
        (isCard ? classes.cardAvatar : classes.avatar) +
        ' ' +
        (customClasses || '')
      }
    >
      <img
        alt=""
        style={loaded ? {} : { display: 'none' }}
        src={imageUrl}
        onLoad={() => {
          setLoaded(true)
        }}
      />
      {
        <span style={loaded ? { display: 'none' } : {}}>
          {data.status === 'Vendor Input'
            ? 'UA'
            : arr.length === 1
            ? arr[0].charAt(0).toUpperCase()
            : arr[0].charAt(0).toUpperCase() +
              '' +
              arr[1].charAt(0).toUpperCase()}
        </span>
      }
    </Avatar>
  )
}

export default CustomAvatar
