import { nanoid } from 'nanoid'
import axios from 'axios'
import { AccessTokenType, JWK } from './tokens'
import { injectBearer } from './format'
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  JWKS,
  NONCE,
  PRAXIS_REDIRECT_AFTERAUTH,
} from '../constants'

/*
    ____________________________________________________
   | STORAGE                                            |
   |____________________________________________________|
   | Helper methods to manage resources in localStorage |
   |____________________________________________________|
*/

export const storageSet = (k: string, v: string): void => {
  try {
    window.localStorage.setItem(k, v)
  } catch (e) {
    console.error(e)
  }
}

export const storageGet = (k: string): string | null => {
  return window.localStorage.getItem(k)
}

export const storageClear = (k: string): void => {
  window.localStorage.removeItem(k)
}

export const setKeys = (
  accessToken: string,
  accessTokenKey: string = ACCESS_TOKEN,
  accessTokenType: AccessTokenType = '',
  idToken: string
): void => {
  storageSet(
    accessTokenKey,
    accessTokenType === 'Bearer' ? injectBearer(accessToken) : accessToken
  )
  storageSet(ID_TOKEN, idToken)
}

export const getRedirect = (): string | null => {
  const redirect = storageGet(PRAXIS_REDIRECT_AFTERAUTH)
  if (redirect) {
    storageClear(PRAXIS_REDIRECT_AFTERAUTH)

    // Don't return a redirect URL if that URL is already loaded
    return redirect !== window.location.href ? redirect : null
  }
  return null
}

export const getNonce = (): string => {
  let nonceCheck = storageGet(NONCE)
  if (nonceCheck) {
    return nonceCheck
  } else {
    // no nonce set, so generate a random one
    const nonce = nanoid()
    storageSet(NONCE, nonce)
    return nonce
  }
}

interface JWKSResponse {
  keys: JWK[]
}
export const getJWKS = async (jwksUri: string): Promise<JWK[] | null> => {
  const jwksCheck = storageGet(JWKS)
  if (jwksCheck) {
    try {
      const jwks: JWK[] = JSON.parse(jwksCheck)
      return jwks
    } catch (e) {
      console.error(e)
      storageClear(JWKS)
      return null
    }
  } else {
    try {
      const fetch = await axios.get(jwksUri)
      const jwks: JWKSResponse = fetch.data // all the possible public keys
      const filteredJwks = jwks.keys.filter(
        (key: JWK) =>
          key.use === 'sig' && // JWK property `use` determines the JWK is for signature verification
          key.kty === 'RSA' && // We are only supporting "key type" RSA (RS256)
          key.kid && // The "key id" must be present to be useful for later
          key.x5c &&
          key.x5c.length // Has useful public key
      )
      storageSet(JWKS, JSON.stringify(filteredJwks))
      return filteredJwks
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

export const clearAllStorage = (
  accessTokenKey: string = ACCESS_TOKEN
): void => {
  storageClear(accessTokenKey)
  storageClear(ID_TOKEN)
  storageClear(PRAXIS_REDIRECT_AFTERAUTH)
  storageClear(NONCE)
}
