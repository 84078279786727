import { AuthenticationBaseProps } from './AuthenticationProps'
import { useEffect, useReducer } from 'react'
import Session from '../Session'

interface LoginErrorAction extends Action<'loginError'> {
  error: Error | null
}
interface LoginAction extends Action<'login'> {
  session: Session | null
}
interface LogoutAction extends Action<'logout'> {}
interface LoginBlockAction extends Action<'loginBlocking'> {}
interface Action<T extends string> {
  type: T
  callback?: () => void
}
type Actions = LoginErrorAction | LoginAction | LogoutAction | LoginBlockAction

type AuthReducer = (prevState: AuthState, action: Actions) => AuthState

const authReducer: AuthReducer = (prevState, action) => {
  switch (action.type) {
    case 'login':
      return {
        ...prevState,
        session: action.session,
        loginCheck: true,
        logoutCheck: false,
        callback: action.callback,
      }
    case 'loginBlocking':
      return {
        ...prevState,
        loginCheck: false,
        logoutCheck: false,
        callback: action.callback,
      }
    case 'loginError':
      return {
        ...prevState,
        error: action.error,
        loginCheck: true,
        logoutCheck: false,
        callback: action.callback,
      }
    case 'logout':
      return {
        ...prevState,
        session: null,
        logoutCheck: true,
        callback: action.callback,
      }
    default:
      return prevState
  }
}

interface AuthState {
  loginCheck: boolean
  logoutCheck: boolean
  session: Session | null
  error: Error | null
  callback?: () => void
}

const defaultState: AuthState = {
  loginCheck: false,
  logoutCheck: false,
  session: null,
  error: null,
}

export interface UseAuthState extends Pick<AuthState, 'session'> {
  canRender: boolean
  loginAction: (session?: Session | null, callback?: () => void) => void
  logoutAction: (callback?: () => void) => void
  loginErrorAction: (error: Error, callback?: () => void) => void
  blockingCallback: (callback: () => void) => void
}

const useAuthState = ({
  onLogin = () => {},
  onLogout = () => {},
}: Pick<AuthenticationBaseProps, 'onLogin' | 'onLogout'>): UseAuthState => {
  const [state, dispatch] = useReducer(authReducer, defaultState)
  const { loginCheck, logoutCheck, error, session } = state

  // async callbacks based on state
  useEffect(() => {
    // login check has been completed, call onLogin
    if (loginCheck && (session || error)) {
      onLogin(error, session)
    }
    // logout has been done, call onLogout
    if (logoutCheck) {
      onLogout()
    }
    // if a callback was provided by an action, call it
    if (typeof state.callback === 'function') {
      state.callback()
    }
  }, [loginCheck, logoutCheck, state, onLogin, error, session, onLogout])

  return {
    canRender: loginCheck,
    session,
    loginAction: (session = null, callback?) => {
      dispatch({ type: 'login', session, callback })
    },
    logoutAction: (callback?) => {
      dispatch({ type: 'logout', callback })
    },
    loginErrorAction: (error, callback?) => {
      dispatch({ type: 'loginError', error, callback })
    },
    blockingCallback: (callback) => {
      dispatch({ type: 'loginBlocking', callback })
    },
  }
}

export default useAuthState
