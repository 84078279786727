import {
  checkStringIsNonEmpty, getCreationFailedMessage,
  validate,
} from '../validate'
import constants from './constants'

/**
 * Validates the parameters expected by the Harbinger API,
 * excluding event-specific parameters.
 * @memberOf module:harbinger/models
 * @param {object} config An object whose properties are API parameters ('appId', 'segment', 'visitorId').
 * @returns {object} An AppConfig instance whose (unwritable) properties are valid API keys.
 * @throws {Error} Throws error if one or more param is invalid. console.warn's all validation errors.
 */
class AppConfig {
  constructor (config) {
    // Gather expected configuration
    const {appId, segment, visitorId, apiKey} = config

    config = {appId, segment, apiKey}

    // Provide a default value for 'segment'.
    if (!config.segment) {
      config.segment = constants.APP_CONFIG.DEFAULT_VALUES.SEGMENT
    }

    let constructionValidation = validate
    // Validate each config property
    Object.keys(config).forEach((key, index, keys) => {
      const value = config[key]

      this[key] = value

      let currentValidation = constructionValidation(value, key, checkStringIsNonEmpty)

      if (index === (keys.length - 1)) currentValidation.done(getCreationFailedMessage('AppConfig'))
      else constructionValidation = currentValidation.and
    })

    this.visitorId = visitorId

    Object.freeze(this)
  }
}

export default AppConfig
