import axios, { AxiosResponse } from 'axios'
import jwt from 'jsonwebtoken'
import { getWellKnownUrl } from './urls'
import { injectBearer, removeBearer } from './format'
import { storageClear, getJWKS } from './storage'
import { JWKS } from '../constants'
import isDevEnv from './isDevEnv'

/*
    _______________________________________________
   | TOKENS                                        |
   |_______________________________________________|
   | Helper methods and logic for token validation |
   |_______________________________________________|
*/

/**
 * AccessTokenTypes used to limit options: either 'Bearer' or an empty string
 * @typedef {"Bearer" | ""} AccessTokenType
 */
export type AccessTokenType = 'Bearer' | ''

interface WellKnownResponse {
  issuer: string
  authorization_endpoint: string
  token_endpoint: string
  jwks_uri: string
  response_types_supported: string[]
  subject_types_supported: string[]
  id_token_signing_alg_values_supported: jwt.Algorithm[]
  userinfo_endpoint: string
  registration_endpoint: string
  scopes_supported: string[]
  claims_supported: string[]
  grant_types_supported: string[]
  acr_values_supported: string[]
  token_endpoint_auth_methods_supported: string[]
  display_values_supported: string[]
  claim_types_supported: string[]
  service_documentation: string
  ui_locales_supported: string[]
  userinfo_signing_alg_values_supported: jwt.Algorithm[]
}
export interface UserInfoResponse {
  sub: string
  firstname: string
  lastname: string
  mail: string
  lanid: string
  memberof: string[]
}
// fetches user info from an API
// this does not give all the information that would normally be found in an ID token
// used when only access token is provided and no ID token (Cypress tests)
export const fetchUserInfo = async (
  accessToken: string,
  authorizationUrl: string
): Promise<UserInfoResponse | null> => {
  // Convert authorizationURL from props to well-known URL
  const wellKnownURL = getWellKnownUrl(authorizationUrl)
  if (!wellKnownURL) return null
  try {
    const response: AxiosResponse<WellKnownResponse> = await axios.get(
      wellKnownURL.toString()
    )
    const wellKnown = response.data
    const userInfoEndpoint = wellKnown['userinfo_endpoint']
    const userInfo: AxiosResponse<UserInfoResponse> = await axios.get(
      userInfoEndpoint,
      {
        headers: { Authorization: injectBearer(accessToken) },
      }
    )
    return userInfo.data
  } catch (e) {
    return null
  }
}

// https://github.com/auth0/node-jwks-rsa/blob/4446484a5373d7a65873616a7c4d7889e48f00f3/src/utils.js#L1-L5
// formats X. 509 Certificate Chain to PEM format
// cert is expected to be "x5c" from a JWK @see X.509 Certificate Chain (https://datatracker.ietf.org/doc/html/rfc7517#section-4.7)
export const certToPEM = (cert: string): string => {
  const chunks = cert.match(/.{1,64}/g) || []
  cert = `-----BEGIN CERTIFICATE-----\n${chunks.join(
    '\n'
  )}\n-----END CERTIFICATE-----\n`
  return cert
}

/**
 * JSON Web Key definition
 * @see https://datatracker.ietf.org/doc/html/rfc7517
 */
export interface JWK {
  kty: string
  kid: string
  use: string
  n: string
  e: string
  x5c: string[]
}

export const getPublicKeyForKid =
  (keys: JWK[]): jwt.GetPublicKeyOrSecret =>
  (header: jwt.JwtHeader, cb: jwt.SigningKeyCallback): void => {
    // matches the `kid` found in the token header with a JWK gathered from ID2's openid-configuration
    var key = keys.find((k) => k.kid === header.kid)
    // cb expects a signing key which is a X.509 certificate in PEM format
    // format the certificate found in the matching JWK (from the previous step)
    key ? cb(null, certToPEM(key.x5c[0])) : cb(new Error('kid not found.'))
  }
// verifies tokens asynchronously and tries to find the matching `kid` in given keys
export const verifyToken = async (
  token: string,
  options: jwt.VerifyOptions,
  keys: JWK[]
): Promise<object | undefined> => {
  return new Promise((resolve, reject) => {
    jwt.verify(token, getPublicKeyForKid(keys), options, (err, decoded) => {
      if (err) reject(err)
      resolve(decoded)
    })
  })
}

export interface JWTBody {
  /** JSON Web Token ID */
  jti: string
  /** token issuer */
  iss: string
  /** time token was issued */
  iat: number
  /** time token expires */
  exp: number
  /** authenticator assurance level */
  aal: string
  /** JSON Web Key ID for signature verification */
  kid: string
  /**
   * @note GSP claim
   * @see kid
   */
  sky?: string
  /**
   * subject type
   * @note GSP claim
   * @example "G" // indicates anonymous guests
   * @example "R" // indicates logged in guest
   */
  sut?: string
}

/** The body of a decoded access token */
export interface Access extends JWTBody {
  /** subject */
  sub?: string
  /** user lanId */
  username: string
  /** scopes client has access to */
  scope: string[]
  /** OAuth client ID used to create tokens */
  client_id: string
  /** direct use of a shared symmetric key */
  dir: string
  /** value used to associate a client session with JSON Web Tokens */
  nonce?: string
  /**
   * email ID for logged in guests
   * @note GSP claim
   */
  eid?: string
  /**
   * scopes client has access to
   * @note GSP claim
   * @note "." separated values
   * @see scope
   */
  sco?: string
}

export interface IdentityBase extends JWTBody {
  /** subject */
  sub: string
  /**
   * assurance
   * @note GSP claim
   * @example "L"
   */
  ass?: string
  /** time when the authentication occurred */
  auth_time: number
  /** value used to associate a client session with JSON Web Tokens */
  nonce: string
  /** client ID */
  cli?: string
  /**
   * profile
   * @note GSP claim
   */
  pro?: {
    fn: null
    em: null
    ph: boolean
    led: null
    lty: boolean
  }
  /** authorized party - party to which the ID token was issued */
  azp: string
  /** audience */
  aud: string
  /** access token hash value */
  at_hash: string
  /** authentication context class reference */
  acr: string
  /** client ID*/
  client_id?: string
  /** user email */
  mail: string
  /** user first name */
  firstname: string
  /** user last name */
  lastname: string
  /** user lan ID */
  samaccountname: string
  /** user AD group affiliation */
  memberof: string[]
}

/** The body of a decoded identity token */
export interface Identity extends IdentityBase {
  [key: string]: any // advanced client config
}

/**
 * Validates a given access token and ID token
 *
 * [1] gathers necessary materials needed to validate the tokens from a well-known endpoint
 * [2] validate the access token using cached JWKS, if the validation fails re-fetch JWKS and try again
 * [3] validate identity token if provided
 * @param accessToken      - access token to be validated
 * @param idToken          - ID token to be validated
 * @param authorizationUrl - the authorization URL used to generate the tokens, used to get keys and other validation materials
 * @param clientId         - the client ID used to generate the tokens, used to validate the ID token
 * @param nonce            - unique value that was used to generate tokens, used to validate ID token and protect against replay attacks
 * @returns a promise of whether both tokens are valid or not
 */
export const tokensAreValid = async (
  accessToken: string,
  idToken: string | null,
  authorizationUrl: string,
  clientId: string,
  nonce: string
): Promise<boolean> => {
  // [1] gathers necessary materials needed to validate the tokens from a well-known endpoint
  // Convert authorizationURL from props to well-known URL
  const wellKnownURL = getWellKnownUrl(authorizationUrl)
  if (!wellKnownURL) {
    console.error('Could not get well-known url')
    return false // couldn't generate a well-known url, bad authorization URL
  }

  let algorithms: jwt.Algorithm[], issuer: string, keys: JWK[], jwksUri: string
  try {
    const wellKnown: AxiosResponse<WellKnownResponse> = await axios.get(
      wellKnownURL.toString()
    )
    jwksUri = wellKnown.data.jwks_uri
    const jwksCheck = await getJWKS(jwksUri)
    if (!jwksCheck) {
      return false // couldn't get JWKS
    }
    keys = jwksCheck
    issuer = wellKnown.data.issuer
    algorithms = wellKnown.data.id_token_signing_alg_values_supported // currently no specific one for access tokens so using ID token algorithms
  } catch (e) {
    return false // couldn't get well-known URL response
  }

  // [2] validate the access token using cached JWKS, if the validation fails re-fetch JWKS and try again
  // https://auth0.com/docs/tokens/guides/jwt/verify-jwt-signature-using-jwks
  let attempts = 0
  let accessTokenDecoded: Access | null = null // decode so we can use it later to check id token subject matches access token username
  const accessConfig = {
    algorithms,
    issuer,
  }
  do {
    try {
      accessTokenDecoded = (await verifyToken(
        removeBearer(accessToken),
        accessConfig,
        keys
      )) as Access
    } catch (e) {
      if (isDevEnv()) {
        console.error(e)
      }
      if (attempts > 0) return false // we failed twice
    }
    if (!accessTokenDecoded) {
      storageClear(JWKS)
      const jwksCheck = await getJWKS(jwksUri)
      if (jwksCheck) {
        keys = jwksCheck
      } else {
        if (isDevEnv()) {
          console.error(
            'Praxis Error: failed to get JWKS and thus cannot validate tokens'
          )
        }
        return false // couldn't get JWKS
      }
      attempts++
    }
  } while (!accessTokenDecoded && attempts <= 1)

  // [3] validate identity token if provided
  if (idToken) {
    const idConfig = {
      algorithms,
      audience: clientId, // make sure ID token was generated for this client ID
      issuer,
      nonce,
      subject: accessTokenDecoded?.username, // make sure access and identity tokens represent the same entity
    }
    try {
      await verifyToken(idToken, idConfig, keys)
    } catch (e) {
      if (isDevEnv()) {
        console.error(e)
      }
      return false // ID token couldn't be verified
    }
  }

  return true // all is well
}
